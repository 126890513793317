<template>
  <div>
    <v-menu v-model="show" :attach="$el" right offset-y nudge-bottom="10px">
      <template v-slot:activator="{ on, attrs, value }">
        <Chip v-bind="attrs" v-on="on">
          <span>{{ valueToLabel(name) }}</span>
          <v-icon class="ml-2" color="primary" v-if="value">mdi-menu-up</v-icon>
          <v-icon class="ml-2" color="primary" v-else>mdi-menu-down</v-icon>
        </Chip>
      </template>
      <List :list="chain" @change="change" v-model="currentChaina">
        <!-- <template v-slot:default="{ value }">
          {{ value }}
        </template> -->
      </List>
    </v-menu>
  </div>
</template>

<script lang="js">
import Chip from '@/components/Chip.vue';
import List from '@/components/List.vue';
import {  init as metaMaskInit } from '@uptickjs/uptick-web3-sdk/src/index';

export default {
  name: 'ChainSelect',
  components: { Chip, List },
  props: {
      value: String
  },
  data: function() {
    return {
        show: false,
        chain: ['UPTICK(COSMOS)', 'UPTICK(EVM)'].map((v) => ({
            value: v,
            label: v,
        })),
        //  chain: [ 'UPTICK(EVM)'].map((v) => ({
        //     value: v,
        //     label: v,
        // })),
        name:'',
        currentChaina: this.valueToLabel(localStorage.getItem('KEY_CHAIN') || 'UPTICK')
    };
  },
  methods:{
      change: function (lbl){
        this.labelToValue(lbl);
        localStorage.setItem("KEY_CHAIN", this.name);
        this.setWallet(this.name);
        location.reload();
      },
      setWallet(name) {
          // if(name == "UPTICK") {
          //     keplrInit(this.name);
          // }
          // else if(name == "UPTICK EVM") {
          //     metaMaskInit(this.name);
          // }
           metaMaskInit(this.name);
      },
      valueToLabel(val) {
          if(val == "UPTICK") {
              return "UPTICK(COSMOS)";
          } else if (val == "UPTICK EVM") {
              return "UPTICK(EVM)";
          }
      },
      labelToValue(lbl) {
          if (lbl == "UPTICK(COSMOS)") {
              this.name = "UPTICK";
          } else if(lbl == "UPTICK(EVM)") {
              this.name = "UPTICK EVM";
          }
      }
  },
  mounted(){
    this.name="UPTICK EVM" // UPTICK，UPTICK EVM
    let chain = localStorage.getItem("KEY_CHAIN");
    if(!chain) {
        localStorage.setItem("KEY_CHAIN", this.name);
    } else {
        this.name = chain;
    }
    this.setWallet(this.name);
  }
};
</script>

<style lang="scss" scoped></style>
