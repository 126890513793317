import { $postThird } from '@/utils/request';
import { BASE_URL, BASE_URL_S3 } from '../config';

// import service from "@/utils/request"
import { BASE_IMAGE_URL, BASE_JSON_URL } from '../config';

export function uploadImage(files, onUploadProgress) {
  const formData = new FormData();
  formData.append('image', files);
  debugger;

  //xxl post third
  return $postThird('/upImage', formData, {
    headers: {
      'Content-Type': 'application/octet-stream',
      accessKey: 'uptick',
      accessKeySecret: 'AF0F59F1EEC1728CCFCD5B7B6203D229',
    },
    onUploadProgress,
  });
}

export function uploadJson(files) {
  const formData = new FormData();
  formData.append('file', JSON.stringify(files));
  debugger;

  //xxl post third
  return $postThird('/upImage', formData, {
    headers: {
      'Content-Type': 'application/json',
      accessKey: 'uptick',
      accessKeySecret: 'AF0F59F1EEC1728CCFCD5B7B6203D229',
    },
  });
}
// 批量上传图片
export function uploadImages(file, onUploadProgress) {
  const formData = new FormData();
  for (let i = 0; i < file.length; i++) {
    formData.append('images', file[i]);
  }

  //xxl post third
  return $postThird('/multUpImage', formData, {
    headers: {
      'Content-Type': 'application/octet-stream',
      accessKey: 'uptick',
      accessKeySecret: 'AF0F59F1EEC1728CCFCD5B7B6203D229',
    },
    onUploadProgress,
  });
}

// 添加投放预览图和多媒体信息
export function lazyAddPreview(data) {
  //xxl post third
  return $postThird(
    `${BASE_URL}/lazynft/add/preview.do`,
    JSON.stringify(data),
    {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
    }
  );
}

export function uploadJSON2IPFS(object) {
  const formData = new FormData();
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      const element = object[key];
      formData.append(key, element);
    }
  }

  debugger;
  return $postThird('/upJson', formData, {
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      accessKey: 'uptick',
      accessKeySecret: 'AF0F59F1EEC1728CCFCD5B7B6203D229',
    },
  });
}

//图片显示
export function getNftImg(hash) {
  // console.log("wxl --- getNftImg",hash,'https://s3.uptick.network/' + hash + '/small')
  let imgurl = hash.indexOf('http');
  if (imgurl == -1) {
    return 'https://s3.uptick.network/' + hash + '/small';
  } else {
    return hash;
  }
}
export function getNftIpsh(hash) {
  // console.log("wxl --- getNftImg",hash,'https://s3.uptick.network/' + hash + '/small')

  return 'https://ipfs.upticknft.com/ipfs/' + hash;
}
//图片显示
export function getImgUrl2Hash(url) {
  url = url.replace('https://s3.uptick.network/', '');
  url = url.replace('/small', '');

  return url;
}
//图片IPFS地址
export function getIPFSurl(hash) {
  return 'https://s3.uptick.network' + hash + '.json';
}

//图片IPFS地址
export function createNftIPFSurl(hash) {
  return 'https://s3.uptick.network' + hash;
}
