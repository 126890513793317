<template>
  <div class="contains" :class="{ mobile: isMobile }">
    <div class="list" v-for="(item, index) in list" :key="index">
      <div
        class="item"
        @click="toCollectPage(item)"
        :style="{
          backgroundImage: `url(${
            'https://s3.uptick.network/' + item.imgUrl + '/small'
          })`,
        }"
      >
        <div class="overlay">
          <div class="boxInfo d-flex flex-lg-row flex-column">
            <div class="left">
              <div class="backavaimg">
                <img
                  class="avaImg"
                  :src="'https://s3.uptick.network/' + item.imgUrl + '/small'"
                  alt=""
                />
              </div>
              <div class="name ml-3">{{ item.name }}</div>
              <div class="collect ml-3 d-flex flex-row align-center">
                <img
                  src="@/assets/icons/icon_collection.png"
                  v-if="
                    item.contractType == 'COLLECTION' ||
                    item.contractType == 'LAZYN_COLLECTION'
                  "
                  alt=""
                  width="15"
                  height="15"
                />
                <img
                  src="@/assets/icons/icon_folder.png"
                  v-else
                  alt=""
                  width="15"
                  height="15"
                />
                <div class="amount ml-4">{{ item.nftCount }} Items</div>
              </div>
              <div class="avatar d-flex flex-row align-center ml-3">
                <Avatar
                  :did="item.owner"
                  :size="35"
                  :hash="item.creatorPhoto"
                  :grade="item.grade"
                  imgType="middlev"
                />
                <div class="Username ml-3">{{ item.creatorName }}</div>
              </div>
            </div>
            <div class="right">
              <div class="nftlist d-flex flex-row" v-if="!isMobile">
                <div
                  class="nftitem"
                  v-for="(item, index) in item.listAssets"
                  :key="index"
                >
                  <img
                    class="imgsrc"
                    @click.stop="toSaleDetail(item)"
                    :src="'https://s3.uptick.network/' + item.imgUrl + '/small'"
                    alt=""
                  />
                  <div class="nftname">{{ item.name }}</div>
                  <div class="nftprice">
                    {{ item.marketPrice }} {{ item.tokenType }}
                  </div>
                </div>
              </div>
              <div class="nftlist d-flex flex-row mt-4" v-else>
                <div
                  class="nftitem"
                  v-for="(item, index) in item.listAssets"
                  :key="index"
                >
                  <img
                    class="imgsrc"
                    @click.stop="toSaleDetail(item)"
                    :src="'https://s3.uptick.network/' + item.imgUrl + '/small'"
                    alt=""
                  />
                  <div class="nftname">{{ item.name }}</div>
                  <div class="nftprice">
                    {{ item.marketPrice }} {{ item.tokenType }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardList from '@/components/workCard/CardList.vue';
import Avatar from '@/components/avatar/index';
import api from '@/api';

export default {
  name: 'Gallery',
  components: { CardList, Avatar },
  data: function () {
    return {
      list: [],
      pageNumber: 0,
      pageSize: 3,
      totalPage: false,
      isShowLoading: false,
      ending: false,
      imgSrc:
        'https://s3.uptick.network/QmPXSTUvjU5RB5NoFCvkEvDHPFaB5WJDQuqCx53ieemfvu/small',
      dropLogo:
        'https://s3.uptick.network/QmfA2jvXXyHGvimk2XbR5JzDPLgRNhwUNBByVcEjocEUWn/small',
      nftList: [
        {
          img: 'https://s3.uptick.network/QmZzf4M2EpmHWoEvKKmQf1CjPXx8Lo1hL17Aetbx48q3Cf/small',
          name: 'Crystal Artefacts Collection #54',
          price: '3 ATOM',
        },
        {
          img: 'https://s3.uptick.network/QmZzf4M2EpmHWoEvKKmQf1CjPXx8Lo1hL17Aetbx48q3Cf/small',
          name: 'Crystal Artefacts Collection #54',
          price: '3 ATOM',
        },
        {
          img: 'https://s3.uptick.network/QmZzf4M2EpmHWoEvKKmQf1CjPXx8Lo1hL17Aetbx48q3Cf/small',
          name: 'Crystal Artefacts Collection #54',
          price: '3 ATOM',
        },
      ],
      mobileNftList: [
        {
          img: 'https://s3.uptick.network/QmZzf4M2EpmHWoEvKKmQf1CjPXx8Lo1hL17Aetbx48q3Cf/small',
          name: 'Crystal Artefacts Collection #54',
          price: '3 ATOM',
        },
        {
          img: 'https://s3.uptick.network/QmZzf4M2EpmHWoEvKKmQf1CjPXx8Lo1hL17Aetbx48q3Cf/small',
          name: 'Crystal Artefacts Collection #54',
          price: '3 ATOM',
        },
      ],
    };
  },
  mounted() {
    this.getList();
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    toCollectPage(item) {
      let url;
      if (item.linkUrl) {
        url = item.linkUrl;
      } else {
        url = item.address;
      }
      if (
        item.contractType == 'COLLECTION' ||
        item.contractType == 'LAZYN_COLLECTION'
      ) {
        this.$router.push({
          path: `/collection/${url}/firspublish/${item.owner}`,
        });
      } else {
        this.$router.push({
          path: `/folder/${url}/firspublish/${item.owner}`,
        });
      }
    },
    toSaleDetail(item) {
      let query = {};
      query.nftAddress = item.nftAddress;
      query.nftId = item.nftId;
      query.owner = item.owner;
      if (item.marketType == '2') {
        this.$router.push({ name: 'Detail', query });
      } else {
        this.$router.push({ name: 'Saledetail', query });
      }
    },

    getList() {
      let params = {
        chainType: 'IRIS_IRISHUB',
      };
      this.pageNumber++;
      //xxl home page
      //conole.log();
      this.isShowLoading = true;

      return api.home
        .getCollectNftExplore({
          ...params,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        })
        .then((response) => {
          const { list, totalPage } = response.data;
          this.pageNumber;
          this.totalPage = totalPage;
          if (this.pageNumber == 1) {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          this.isShowLoading = false;
          if (this.totalPage == this.pageNumber) {
            this.ending = true;
          }
        });
    },
    async loadList() {
      if (this.totalPage > this.pageNumber) {
        this.$gtag.event('NFT浏览-加载更多', {
          event_category: 'Click',
          event_label: 'NFT',
        });
        await this.getList();
      }
    },
    async onScroll(e) {
      const { scrollTop, clientHeight, scrollHeight } =
        e.target.scrollingElement;
      if (
        scrollTop + clientHeight === scrollHeight &&
        this.totalPage > this.pageNumber
      ) {
        await this.getList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contains {
  .list {
    position: relative;
    .item {
      cursor: pointer;
      width: 100%;
      aspect-ratio: 10/3;
      border-radius: 15px;

      background-size: cover;
      background-position: center;
      margin-bottom: 30px;
      background-color: rgba(0, 0, 0, 0.5);
    }
    .overlay {
      padding: 3.5% 2% 0 5%;
      border-radius: 15px;
      // position: absolute;
      // top: 0;
      // left: 0;
      width: 100%;
      aspect-ratio: 10/3;
      background-color: rgba(0, 0, 0, 0.5);
      .boxInfo {
        .left {
          width: 28%;
          .backavaimg {
            width: 60%;
            aspect-ratio: 1 / 1;

            display: flex;
            align-items: center;
            justify-content: center;
            .avaImg {
              width: 100%;
              aspect-ratio: 1 / 1;
              object-fit: cover;
              border-radius: 30px;
              border: 10px solid #ffffff;
              cursor: pointer;
              object-fit: cover;
              // border-color: rgb(255, 255, 255,0.5);
              // opacity: 0.5;
            }
          }
          .name {
            margin-top: 2%;
            width: 55%;
            font-family: Helvetica;
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            line-height: 24px;
            letter-spacing: 0px;
            color: #ffffff;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .collect {
            margin-top: 3%;
            .amount {
              font-family: Helvetica;
              font-size: 15px;
              font-weight: normal;
              font-stretch: normal;
              line-height: 24px;
              letter-spacing: 0px;
              color: #ffffff;
            }
          }
          .avatar {
            margin-top: 5%;
            .Username {
              width: 150px;
              font-family: Helvetica;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              line-height: 20px;
              letter-spacing: 0px;
              color: #ffffff;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
        .right {
          // margin-left: 6%;
          width: 72%;
          .nftlist {
            width: 100%;
            .nftitem {
              margin-right: 3%;
              width: 30%;
              .imgsrc {
                width: 100%;
                aspect-ratio: 1 / 1;
                background-color: #270645;
                border-radius: 10px;
                cursor: pointer;
              }
              .nftname {
                font-family: Helvetica;
                font-size: 15px;
                font-weight: bold;
                font-stretch: normal;
                line-height: 24px;
                letter-spacing: 0px;
                color: #ffffff;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .nftprice {
                font-family: Helvetica;
                font-size: 15px;
                font-weight: normal;
                font-stretch: normal;
                line-height: 24px;
                letter-spacing: 0px;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
  &.mobile {
    .list {
      .item {
      }
      .overlay {
        padding: 5%;
        .boxInfo {
          .left {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .backavaimg {
              width: 25%;
              .avaimg {
                border-radius: 20px;
                border: 7px solid #ffffff;
              }
            }
          }
          .right {
            width: 100%;
            .nftlist {
              .nftitem {
                margin-right: 4%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
