<template>
  <div :class="{ home: true, mobile: isMobile }">
    <transition-group name="fade" mode="out-in">
      <div
        class="slide d-flex flex-lg-row flex-column align-center justify-center"
        :key="currentSlide"
      >
        <div class="home-work d-flex align-center justify-center">
          <!-- <Work
        style="cursor: pointer"
        contain
        :aspectRatio="isMobile ? null : -1"
        :hash="sku.imgUrl"
        :value='sku'
        sourcetop=true
        :videoPic='videoPic'
        @imgClick="workClick"
      /> -->

          <img class="imgUrl" :src="sku[currentSlide].imgUrl" alt="Slide" />
        </div>
        <div class="d-flex flex-column product">
          <div
            class="d-flex align-center mt-9 mt-lg-11"
            :style="isMobile ? 'margin-top:13px !important' : ''"
          >
            <Avatar
              :did="sku[currentSlide].owner"
              :size="isMobile ? 42 : 40"
              :hash="sku[currentSlide].creatorPhoto"
              imgType="middlev"
              :grade="sku[currentSlide].grade"
              showAvatarInfo
            />
            <div
              class="ml-7 ml-lg-4 fs-15 name"
              :class="{ darkFont: this.$vuetify.theme.dark }"
              :style="isMobile ? 'margin-left:15px !important' : ''"
            >
              {{
                sku[currentSlide].creatorName
                  ? sku[currentSlide].creatorName
                  : sku[currentSlide].owner.substr(0, 6) +
                    '...' +
                    sku[currentSlide].owner.substr(-6)
              }}
            </div>
          </div>

          <div
            class="mt-4 mt-lg-6 fs-60 font-weight-bold work-name"
            :class="{ darkFont: this.$vuetify.theme.dark }"
          >
            {{ sku[currentSlide].name }}
          </div>
          <template v-if="sku[currentSlide].marketType == '2'">
            <div v-if="!isStart">
              <div
                class="mt-3 mt-lg-7 fs-15 font-weight-bold t1"
                :class="{ darkFont: this.$vuetify.theme.dark }"
              >
                {{ $t('homeProductAuctionStartingIn') }}
              </div>
              <div
                class="mt-4 mt-lg-3 fs-30 font-weight-bold t2"
                :class="{ darkFont: this.$vuetify.theme.dark }"
              >
                {{ hour }}h {{ minutes }}m {{ second }}s
              </div>
              <div
                class="mt-3 mt-lg-6 fs-15 font-weight-bold t1"
                :class="{ darkFont: this.$vuetify.theme.dark }"
              >
                {{ $t('workCardStartBid') }}
              </div>
            </div>
            <div v-else>
              <div
                class="mt-3 mt-lg-7 fs-15 font-weight-bold t1"
                :class="{ darkFont: this.$vuetify.theme.dark }"
              >
                {{ $t('homeProductAuctionEndingIn') }}
              </div>
              <div
                class="mt-4 mt-lg-3 fs-30 font-weight-bold t2"
                :class="{ darkFont: this.$vuetify.theme.dark }"
              >
                {{ hour }}h {{ minutes }}m {{ second }}s
              </div>

              <div
                v-if="sku.auctionNoBid == true"
                class="mt-3 mt-lg-6 fs-15 font-weight-bold t1"
                :class="{ darkFont: this.$vuetify.theme.dark }"
              >
                {{ $t('auctionStartBid') }}
              </div>
              <div
                v-else
                class="mt-3 mt-lg-6 fs-15 font-weight-bold t1"
                :class="{ darkFont: this.$vuetify.theme.dark }"
              >
                {{ $t('workCardCurrentBid') }}
              </div>
            </div>
          </template>
          <div v-if="sku.marketType == 5 && sku.couponTime">
            <div
              class="mt-1 mt-lg-4 fs-30 font-weight-bold t2 work-price"
              :class="{ darkFont: this.$vuetify.theme.dark }"
            >
              {{ sku[currentSlide].couponTime.deduction }}
              {{ sku[currentSlide].tokenType }}
            </div>
            <div
              class="irisOriginalPrice"
              style="font-weight: bold"
              :class="{ darkFont: this.$vuetify.theme.dark }"
            >
              {{ sku[currentSlide].marketPrice }}
              {{ sku[currentSlide].tokenType }}
            </div>
            <div
              class="couperTime"
              :class="{ darkFont: this.$vuetify.theme.dark }"
            >
              {{ hour }}h {{ minutes }}m {{ second }}s
            </div>
          </div>

          <div
            class="mt-1 mt-lg-4 fs-30 font-weight-bold t2 work-price"
            :class="{ darkFont: this.$vuetify.theme.dark }"
            v-else
          >
            {{ sku[currentSlide].marketPrice }}
            {{ sku[currentSlide].tokenType }}
          </div>
          <div class="mt-5 mt-lg-2 fs-13" style="color: #766983; display: none">
            USD {{ usd }}
          </div>
          <div class="d-flex align-center mt-10 btn">
            <!--   拍卖-->
            <div v-if="sku[currentSlide].marketType == '2'">
              <div
                v-if="
                  sku[currentSlide].auction.auctionStatus == '0' ||
                  sku[currentSlide].auction.auctionStatus == '1' ||
                  sku[currentSlide].auction.auctionStatus == '2'
                "
              >
                <!--   拍卖可点击 -->
                <div v-if="!isClick">
                  <!--    / 买得起-->
                  <v-btn
                    v-if="isPrice"
                    class="rd"
                    rounded
                    width="225px"
                    height="51px"
                    @click="bidBtn"
                    >{{ $t('detailIndexPlaceBid') }}
                  </v-btn>
                </div>
                <!--   拍卖不可点击-->
                <div v-else>
                  <v-btn class="nord" rounded width="225px" height="51px"
                    >{{ $t('detailIndexPlaceBid') }}
                  </v-btn>
                </div>
              </div>
              <div v-else>
                <v-btn class="nord" rounded width="225px" height="51px"
                  >{{ $t('popupSaletotalSold') }}
                </v-btn>
              </div>
            </div>
            <!--  销售-->
            <div
              v-else-if="
                sku[currentSlide].useType != '1' ||
                sku[currentSlide].denomType == '3'
              "
            >
              <div v-if="sku[currentSlide].minPrice != 0">
                <v-btn
                  v-if="isPrice"
                  class="rd"
                  rounded
                  width="225px"
                  height="51px"
                  @click="buybtn"
                  >{{ $t('popupBuyPriceBuyNow') }}
                </v-btn>
              </div>
              <div v-else>
                <v-btn
                  v-if="isPrice"
                  class="rd"
                  rounded
                  width="225px"
                  height="51px"
                  @click="buybtn"
                  >{{ $t('getForFree') }}
                </v-btn>
              </div>
            </div>
            <!-- <img
          v-if="sku[currentSlide].useType != '1' && isShowIcon"
          @click="buyBtn"
          class="ml-5 ml-lg-5 phone"
           :src=" $vuetify.theme.dark ? darkPhoneBtn :phoneBtn"
          alt="button"
          width="20px"
          height="31px"
        /> -->
          </div>
        </div>
      </div>
    </transition-group>

    <v-dialog v-model="openbid">
      <auction-price
        v-if="openbid"
        :auctionNo="auctionNo"
        :Nft_sku="sku[currentSlide]"
        :buyType="buyType"
        @getOpen="OpenBid"
      ></auction-price>
    </v-dialog>
    <v-dialog v-model="openpay">
      <pay @getOpen="Open" :Nft_sku="sku"></pay>
      <!-- <choose-show @getOpen="Open"  :Nft_sku="sku"></choose-show> -->
    </v-dialog>
    <v-dialog v-model="openbuy">
      <buy-price
        v-if="openbuy"
        :minprice="minprice"
        :buyPrice="buyPrice"
        :buytype="0"
        :marketNftInfo="sku[currentSlide]"
        :assetLevel="1"
        @getOpen="openBuy"
      ></buy-price>
    </v-dialog>
    <v-dialog v-model="openBuySuccess">
      <BuySuccess
        v-if="openBuySuccess"
        :NFT_info="shopinfo"
        :imgUrl="imgUrl"
        @getbuySuccess="openSuccess"
      ></BuySuccess>
    </v-dialog>
  </div>
</template>

<script>
import AuctionPrice from '@/components/popup/auctionPrice';
import Pay from '@/components/popup/pay.vue';
import BuyPrice from '../../components/popup/buyPrice';

import Work from '@/components/work/index.vue';
import Avatar from '@/components/avatar/index.vue';
import api from '@/api';
import phoneBtn from '@/assets/icons/icon_pay.png';
import darkPhoneBtn from '@/assets/darkimage/icon_pay.png';
import productPic from '@/assets/test.png';
import BuySuccess from '@/components/popup/buySuccess.vue';
import { getFileSrc } from '@/utils/file';
const WORK_KEY = 'WORK';

export default {
  name: 'Product',
  components: { Pay, AuctionPrice, Work, Avatar, BuyPrice, BuySuccess },
  props: {
    sku: {
      type: Array,
    },
    address: {
      type: String,
    },
    price: {
      type: String,
    },
  },
  data() {
    return {
      slides: [
        {
          name: '1111',
          img: 'https://s3.uptick.network/Qmcnq2b6M6LAarQ8VKQAR6gYuH8hyuwKZuwyKJ52sEgq8N/small',
        },
        {
          name: '2222',
          img: 'https://s3.uptick.network/QmQVE7tFvL8YaPPV5GaJjCUKdKWr8NyS3xDeBXRbHQTanm/small',
        },
        {
          name: '33333',
          img: 'https://s3.uptick.network/QmWPX9DrjdaQ5QQzw4VGpctFFsJvxYq49EUuzq7Ekpt57d/small',
        },
      ],
      currentSlide: 0,
      shopinfo: {},
      openBuySuccess: '',
      videoPic: '',
      openbid: false,
      openpay: false,
      openbuy: false,
      phoneBtn,
      darkPhoneBtn,
      productPic,
      showAvatarInfo: true,
      minprice: '',
      usd: '',
      isStart: false,
      now: '',
      start: '',
      end: '',
      day: 0,
      hour: 0,
      minutes: 0,
      second: 0,
      seconds: '',
      buyType: '',
      auctionNo: '',
      isClick: false,
      // isPrice: false,
      isPrice: true,
      buyPrice: '',
      isIpad: false,
      imgUrl: '',
      isShowIcon: true,
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  beforeDestroy() {
    if (typeof window === 'undefined') return;

    window.removeEventListener('resize', this.onResize, { passive: true });
  },
  async mounted() {
    setInterval(this.nextSlide, 30000);
    let currentChain = `${this.$env.VUE_APP_MARKET_TYPE}`;
    this.srcName = currentChain;
    if (this.srcName == 'UPTICK') {
      this.isShowIcon = true;
    } else if (
      this.srcName == 'BSC' ||
      this.srcName == 'POLYGON' ||
      this.srcName == 'ARBITRUM'
    ) {
      this.isShowIcon = false;
    }
    this.onResize();
    window.addEventListener('resize', this.onResize, { passive: true });
    this.Time();
    if (this.sku[this.currentSlide].auction)
      this.auctionNo = this.sku.auction.auctionNo;
    if (this.sku.fileType == 'MP4') {
      this.videoPic = await getFileSrc(
        WORK_KEY,
        this.sku[this.currentSlide].fileUrl,
        true
      );
    }

    this.imgUrl = await api.image.getNftImg(this.sku[this.currentSlide].imgUrl);
    if (this.sku[this.currentSlide].couponTime) {
      this.buyPrice = this.sku[this.currentSlide].couponTime.deduction;
    } else {
      this.buyPrice = this.sku[this.currentSlide].marketPrice;
    }
    let _self = this;
    let count = 60;
    this.buyType = 0;
    (function fn() {
      count--;
      setTimeout(() => {
        let usd_price = sessionStorage.getItem('KEY_USD_PRICE');

        _self.usd = (_self.sku[this.currentSlide].minPrice * usd_price).toFixed(
          4
        );
        if (_self.usd == 'NaN') {
          _self.usd = '';
        }
        if (count > 0 && !!usd_price) {
          fn();
        }
      }, 1500);
    })();
  },
  methods: {
    nextSlide() {
      console.log('nextSlide -------------');
      this.currentSlide = (this.currentSlide + 1) % this.sku.length;
    },
    getTime() {
      if (this.sku[this.currentSlide].auction) {
        this.now = Date.parse(new Date());
        this.start = this.sku[this.currentSlide].auction.startTime;
        this.end = this.sku[this.currentSlide].auction.endTime;

        if (this.sku[this.currentSlide].marketType == '2') {
          if (this.now < this.start) {
            this.isStart = false;
            this.isClick = true;
            this.seconds = (this.start - this.now) / 1000;
          } else if (this.now < this.end) {
            this.isStart = true;
            this.isClick = false;
            this.seconds = (this.end - this.now) / 1000;
          } else {
            this.isClick = true;
          }
        }
      }
      if (this.sku[this.currentSlide].couponTime) {
        this.now = Date.parse(new Date());
        this.start = this.sku[this.currentSlide].couponTime.couponStartTime;
        this.end = this.sku[this.currentSlide].couponTime.couponEndTime;
        // this.start = Date.parse(new Date("2022-01-09 13:48:00"));
        // this.end = Date.parse(new Date("2022-01-09 13:49:00"));

        if (this.now < this.start) {
          this.isStart = false;
          this.seconds = (this.start - this.now) / 1000;
        } else if (this.now < this.end) {
          this.isStart = true;
          this.seconds = (this.end - this.now) / 1000;
        }
      }
    },
    // 天 时 分 秒 格式化函数
    countDown() {
      let d = parseInt(this.seconds / (24 * 60 * 60));
      this.day = d < 10 ? '0' + d : d;
      let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
      this.hour = h < 10 ? '0' + h : h;
      let m = parseInt((this.seconds / 60) % 60);
      this.minutes = m < 10 ? '0' + m : m;
      let s = parseInt(this.seconds % 60);
      this.second = s < 10 ? '0' + s : s;
    },
    //定时器没过1秒参数减1
    Time() {
      setInterval(() => {
        if (this.seconds > 0) {
          this.seconds -= 1;
          this.countDown();
        } else {
          this.getTime();
        }
      }, 1000);
    },
    buyBtn() {
      this.openpay = true;
    },
    //弹框关闭后获取open值
    Open(e) {
      this.openpay = e;
    },
    bidBtn() {
      if (!this.$vuetify.breakpoint.mobile) {
        if (!this.$store.state.did) {
          window.eventBus.$emit('isWallet');
          return;
        }
      }
      let query = {};
      query.nftAddress = this.sku[this.currentSlide].nftAddress;
      query.nftId = this.sku[this.currentSlide].nftId;
      query.owner = this.sku[this.currentSlide].owner;
      this.$router.push({ name: 'Detail', query });
    },
    OpenBid(e) {
      this.openbid = e;
    },
    onResize() {
      this.isIpad = 900 < window.innerWidth;
    },
    buybtn() {
      if (!this.$vuetify.breakpoint.mobile) {
        if (!this.$store.state.did) {
          // window.eventBus.$emit("ShowLogoBox");
          window.eventBus.$emit('isWallet');
          return;
        }
      }
      let query = {};
      query.nftAddress = this.sku[this.currentSlide].nftAddress;
      query.nftId = this.sku[this.currentSlide].nftId;
      query.owner = this.sku[this.currentSlide].owner;
      this.$router.push({ name: 'Saledetail', query });
    },

    openBuy(e, info) {
      console.log('openBuy', e, info);
      debugger;
      this.openbuy = e;
      if (info) {
        this.shopinfo = info;
        this.openBuySuccess = true;
      }
    },
    openSuccess(e) {
      this.openBuySuccess = e;
    },
    workClick() {
      debugger;
      if (
        this.sku.marketType == '2' &&
        this.sku.auction &&
        this.sku.auction.auctionNo != null
      ) {
        let query = {};
        query.nftAddress = this.sku.nftAddress;
        query.nftId = this.sku.nftId;
        query.owner = this.sku.owner;
        this.$router.push({ name: 'Detail', query });
      } else {
        let query = {};
        query.nftAddress = this.sku.nftAddress;
        query.nftId = this.sku.nftId;
        query.owner = this.sku.owner;
        this.$router.push({ name: 'Saledetail', query });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
v-dialog {
  width: auto;
  height: auto;
}
.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 800px;
}
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: opacity 3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.work-name {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}
.couperTime {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #270645;
}
.irisOriginalPrice {
  text-decoration: line-through;

  font-family: '\5FAE\8F6F\96C5\9ED1';
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  line-height: 45px;
  color: #766983;
}

.name {
  font-size: 15px;
  font-weight: bold;
}

.rd {
  width: 107px;
  height: 17px;
  font-weight: bold;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  background-color: #7800f4 !important;
}

.nord {
  width: 294px;
  height: 51px;
  background-image: linear-gradient(#766983, #766983),
    linear-gradient(#270645, #270645);
  background-blend-mode: normal, normal;
  border-radius: 25px;
  opacity: 0.9;
  font-weight: bold;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  /*line-height: 100px;*/
  letter-spacing: 0px;
  color: #ffffff !important;
}

.phone {
  width: 20px;
  height: 31px;
  display: inline-block;
}

.home {
  display: flex;
  letter-spacing: 0px;
  line-height: 1;

  .fs-13 {
    font-size: 13px;
  }

  // .fs-15 {
  //   font-size: 24px;
  // }

  .fs-60 {
    font-size: 47px;
    line-height: 55px;
  }

  .fs-30 {
    font-size: 30px;
  }

  .imgUrl {
    // width: 90%;
    max-width: 100%;
    max-height: 590px;
    object-fit: cover;
    border-radius: 10px;
  }
  .home-work {
    width: 60%;
    max-width: 640px;
    max-height: 575px;
  }

  &.mobile {
    flex-direction: column;

    .home-work {
      margin-right: 0;
      width: 100%;
      height: unset;
      max-width: unset;
      max-height: unset;
      margin-top: 100px;
      margin-left: unset;
    }

    .name {
      font-size: 15px;
      font-weight: bold;
    }

    .work-name {
      font-size: 30px !important;
    }
    .work-price {
      font-size: 20px !important;
    }
    .t1 {
      font-size: 15px;
    }

    .t2 {
      font-size: 30px;
    }

    .btn {
      margin-top: 69px;
    }
  }
}
</style>

<style lang="scss">
.work {
  position: relative;

  .icon {
    position: absolute;
    left: 15px;
    bottom: 110px;
  }
}

.product {
  width: 35%;
  height: 575px;
  max-width: 400px;
  max-height: 575px;
  // flex: 1;
  justify-content: center;
  overflow: hidden;
  margin-left: 5%;

  // margin-right: 45px;
  // margin-left: auto;
  //  margin-left:10%;
}

.mobile {
  .imgUrl {
    width: 90% !important;
    max-height: 450px !important;
  }
  .product {
    width: 100%;
    height: unset;
    max-width: 90%;
    margin-right: 0px !important;
    .v-avatar {
      .v-image {
        max-width: 90% !important;
        max-height: 90% !important;
      }
    }

    .work-name {
      // text-align: center;
      font-size: 30px !important;
      width: 275px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
    }

    .work-price {
      font-size: 30px !important;
    }
  }
  .slide {
    // margin:0 10%;
    // height: 900px !important;
  }
}
</style>
