import Vue from 'vue';
import App from './App.vue';
//import "./registerServiceWorker";
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from '@/plugins/i18n';
import VueLazyload from 'vue-lazyload';

import * as filters from './filters';

import '@/styles/index.scss';
import VueVideoPlayer from 'vue-video-player';
import 'video.js/dist/video-js.css';

import VueGtag from 'vue-gtag';
global.Buffer = global.Buffer || require('buffer').Buffer;

// G-8X15FWEVD2
let googleID = process.env.VUE_APP_GOOGLE_ID;

Vue.use(
  VueGtag,
  {
    config: { id: googleID },
  },
  router
);

import DatetimePicker from 'vuetify-datetime-picker';
import toastInit from '@/components/toast';

import { init as extendInit } from '@/utils/extend';
// import { init as metaMaskInit } from '@/metaMask';
import { init as metaMaskInit } from '@uptickjs/uptick-web3-sdk/src/index';
let chainId = process.env.VUE_APP_ADD_NETWORK_CHAIN_ID;
let rpc = process.env.VUE_APP_ADD_NETWORK_CHAIN_UPC_URL;
let chainRpc = process.env.VUE_APP_ADD_NETWORK_CHAIN_UPC_URL;
let chainName = process.env.VUE_APP_UPTICK_CHAIN_NAME;
let symbol = process.env.VUE_APP_ADD_NETWORK_CHAIN_SYMBOL;
let blockExplorerUrls = process.env.VUE_APP_ADD_NETWORK_CHAIN_BLOCK_URL;
// uptick测试链需要
if (chainId == '0x492') {
  rpc = window.location.protocol + '//' + window.location.host + '/uptickNode';
}

//chainId, chainName, symbol, rpcUrl, blockExplorerUrls
let wallet = metaMaskInit(
  rpc,
  chainRpc,
  chainId,
  chainName,
  symbol,
  blockExplorerUrls
);
Vue.prototype.$wallet = wallet.wallet;
Vue.prototype.$chainName = 'UPTICK EVM';
Vue.use(VueLazyload, {
  attempt: 2,
  loading: require('@/assets/white.jpg'),
});

toastInit(Vue, {
  property: '$toastFn',
});

window.eventBus = new Vue();

extendInit();

import {
  timestampToDate,
  timestampToDateTime,
  timestampToTimeHour,
  timestampToTimeMinute,
  timestampToTimeSecond,
  timestampToDateHour,
} from '@/utils/helper.js';
Vue.prototype.timestampToDate = timestampToDate;
Vue.prototype.timestampToDateTime = timestampToDateTime;
Vue.prototype.timestampToTimeHour = timestampToTimeHour;
Vue.prototype.timestampToTimeMinute = timestampToTimeMinute;
Vue.prototype.timestampToTimeSecond = timestampToTimeSecond;
Vue.prototype.timestampToDateHour = timestampToDateHour;

import uToast from '@/components/uToast.vue';
Vue.component('uToast', uToast);

// import basePage from "@/layout/BasePage.vue";
import uComponents from '@/components/uComponents.vue';
Vue.component('uComponents', uComponents);

import promptBox from '@/components/PromptBox.vue';
Vue.component('promptBox', promptBox);

//     type:success,error
Vue.prototype.$toast = function (type, txt, timer) {
  return this.$refs.ucom.toast(type, txt, timer);
};

Vue.prototype.$confirm = function (txt, ps) {
  this.$refs.ucom.confirm(txt, ps);
};

import messageTip from './components/MessageTip';
messageTip(Vue);

import ipfsServer from '@/api/ipfsServer';
ipfsServer();

Vue.prototype.getSessionStr = function (key) {
  let val = sessionStorage.getItem(key);
  return val;
};

Vue.prototype.getSessionObj = function (key) {
  let val = sessionStorage.getItem(key);
  if (val) {
    let obj = JSON.parse(val);
    return obj;
  }
  return null;
};

Vue.use(DatetimePicker);
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.use(VueVideoPlayer);

Vue.config.productionTip = false;

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
  async mounted() {},
}).$mount('#app');
