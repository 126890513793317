var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contains",class:{ mobile: _vm.isMobile }},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"list"},[_c('div',{staticClass:"item",style:({
        backgroundImage: `url(${
          'https://s3.uptick.network/' + item.imgUrl + '/small'
        })`,
      }),on:{"click":function($event){return _vm.toCollectPage(item)}}},[_c('div',{staticClass:"overlay"},[_c('div',{staticClass:"boxInfo d-flex flex-lg-row flex-column"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"backavaimg"},[_c('img',{staticClass:"avaImg",attrs:{"src":'https://s3.uptick.network/' + item.imgUrl + '/small',"alt":""}})]),_c('div',{staticClass:"name ml-3"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"collect ml-3 d-flex flex-row align-center"},[(
                  item.contractType == 'COLLECTION' ||
                  item.contractType == 'LAZYN_COLLECTION'
                )?_c('img',{attrs:{"src":require("@/assets/icons/icon_collection.png"),"alt":"","width":"15","height":"15"}}):_c('img',{attrs:{"src":require("@/assets/icons/icon_folder.png"),"alt":"","width":"15","height":"15"}}),_c('div',{staticClass:"amount ml-4"},[_vm._v(_vm._s(item.nftCount)+" Items")])]),_c('div',{staticClass:"avatar d-flex flex-row align-center ml-3"},[_c('Avatar',{attrs:{"did":item.owner,"size":35,"hash":item.creatorPhoto,"grade":item.grade,"imgType":"middlev"}}),_c('div',{staticClass:"Username ml-3"},[_vm._v(_vm._s(item.creatorName))])],1)]),_c('div',{staticClass:"right"},[(!_vm.isMobile)?_c('div',{staticClass:"nftlist d-flex flex-row"},_vm._l((item.listAssets),function(item,index){return _c('div',{key:index,staticClass:"nftitem"},[_c('img',{staticClass:"imgsrc",attrs:{"src":'https://s3.uptick.network/' + item.imgUrl + '/small',"alt":""},on:{"click":function($event){$event.stopPropagation();return _vm.toSaleDetail(item)}}}),_c('div',{staticClass:"nftname"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"nftprice"},[_vm._v(" "+_vm._s(item.marketPrice)+" "+_vm._s(item.tokenType)+" ")])])}),0):_c('div',{staticClass:"nftlist d-flex flex-row mt-4"},_vm._l((item.listAssets),function(item,index){return _c('div',{key:index,staticClass:"nftitem"},[_c('img',{staticClass:"imgsrc",attrs:{"src":'https://s3.uptick.network/' + item.imgUrl + '/small',"alt":""},on:{"click":function($event){$event.stopPropagation();return _vm.toSaleDetail(item)}}}),_c('div',{staticClass:"nftname"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"nftprice"},[_vm._v(" "+_vm._s(item.marketPrice)+" "+_vm._s(item.tokenType)+" ")])])}),0)])])])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }