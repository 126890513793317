<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        :src="$vuetify.theme.dark ? darkcloseIcon : closeIcon"
        alt=""
      />
      <div
        class="title mb-12"
        :class="{ darkFont: $vuetify.theme.dark }"
        v-if="
          productInfo.deliveryStatus == 0 ||
          productInfo.deliveryStatus == 2 ||
          productInfo.deliveryStatus == 4
        "
      >
        {{ $t('rwa_conirm_delivery') }}
      </div>

      <div
        class="title mb-12"
        :class="{ darkFont: $vuetify.theme.dark }"
        v-else
      >
        {{ $t('rwa_redeem_detail') }}
      </div>

      <div class="baseInfo d-flex flex-lg-row flex-column align-center mb-8">
        <img :src="imgUrl" class="imageUrl" />
        <div class="ml-lg-4 ml-0 mt-lg-0 mt-5" style="width: 100%">
          <div
            class="collectName mb-4"
            :class="{ darkFont: $vuetify.theme.dark }"
          >
            {{ rwaNftDetail.name }}
          </div>
          <div class="productName" :class="{ darkFont: $vuetify.theme.dark }">
            {{ rwaNftDetail.previewName }}
          </div>
          <div class="description" :class="{ darkFont: $vuetify.theme.dark }">
            {{ rwaNftDetail.extensionDescription }}
          </div>
          <div class="collectName" :class="{ darkFont: $vuetify.theme.dark }">
            x 1
          </div>
        </div>
      </div>

      <div class="receiptInfo">
        <div class="receiptName" :class="{ darkFont: $vuetify.theme.dark }">
          {{ $t('rwa_receive_name') }}：{{ productInfo.deliveryName }}
        </div>
        <div class="receiptName" :class="{ darkFont: $vuetify.theme.dark }">
          {{ $t('rwa_contact_number') }}：{{ productInfo.deliveryPhone }}
        </div>
        <div class="receiptName" :class="{ darkFont: $vuetify.theme.dark }">
          {{ $t('rwa_detailed_delivery_address') }}：{{
            productInfo.fullAddress
          }}
        </div>
        <div class="receiptName" :class="{ darkFont: $vuetify.theme.dark }">
          {{ $t('rwa_pay_fee') }}：{{ productInfo.deliveryfee }}
          {{ productInfo.feeTokenType }}
        </div>

        <button
          class="copyBtn mt-2 mb-2"
          v-if="productInfo.deliveryStatus > 3"
          @click="
            onCopyClick(
              $t('rwa_receive_name') +
                ':' +
                productInfo.deliveryName +
                $t('rwa_contact_number') +
                ':' +
                productInfo.deliveryPhone +
                $t('rwa_detailed_delivery_address') +
                ':' +
                productInfo.fullAddress
            )
          "
        >
          {{ $t('rwa_copy_info') }}
        </button>

        <div
          class="receiptName"
          :class="{ darkFont: $vuetify.theme.dark }"
          v-if="productInfo.deliveryStatus == 3"
        >
          {{ $t('rwa_request_time') }}：{{
            timestampToDateTime(productInfo.createTimeLong)
          }}
        </div>
        <div
          class="receiptName"
          :class="{ darkFont: $vuetify.theme.dark }"
          v-if="productInfo.deliveryStatus > 3"
        >
          {{ $t('rwa_request_time') }}：{{
            timestampToDateTime(
              productInfo.deliveryTime == null
                ? productInfo.createTimeLong
                : productInfo.deliveryTimeLong
            )
          }}
        </div>
        <div class="receiptName" :class="{ darkFont: $vuetify.theme.dark }">
          {{ $t('status') }}：{{ getStatus(productInfo.deliveryStatus) }}
        </div>
        <div
          class="receiptName"
          :class="{ darkFont: $vuetify.theme.dark }"
          v-if="productInfo.deliveryStatus > 3"
        >
          {{ $t('rwa_DeliverNO') }}： {{ productInfo.deliveryNumber }}
          <img
            class="copyProfile"
            width="15"
            src="@/assets/icons/icon_copy.png"
            @click="onCopyClick(trackNumberValue)"
          />
        </div>
      </div>

      <!-- 发货方 -->
      <div v-if="productInfo.sender == this.$store.state.did">
        <div v-if="productInfo.deliveryStatus == 4">
          <div class="d-flex flex-row justify-space-between align-center">
            <div class="number" :class="{ darkFont: $vuetify.theme.dark }">
              {{ $t('rwa_DeliverNO') }}
            </div>
            <button class="save" @click="subCommit">
              {{ $t('rwa_save') }}
            </button>
          </div>
          <div class="box">
            <v-text-field
              ref="desValue"
              v-model="trackNumberValue"
              label=""
              :placeholder="$t('rwa_Deliver_putNO')"
              maxLength="80"
              outlined
            >
            </v-text-field>

            <img
              class="copyProfile"
              src="@/assets/icons/icon_copy.png"
              @click="onCopyClick(trackNumberValue)"
            />
          </div>
        </div>

        <div v-if="productInfo.deliveryStatus == 3">
          <div class="number" :class="{ darkFont: $vuetify.theme.dark }">
            {{ $t('rwa_DeliverNO') }}
          </div>
          <div class="box">
            <v-text-field
              ref="desValue"
              v-model="trackNumberValue"
              :placeholder="$t('rwa_Deliver_putNO')"
              label=""
              maxLength="80"
              outlined
            >
            </v-text-field>
          </div>

          <div
            style="
              width: 100%;
              align-items: center;
              justify-content: center;
              display: flex;
            "
          >
            <button
              class="saveBtn"
              :class="{ unsave: !trackNumberValue }"
              :disabled="!trackNumberValue"
              @click="subFahuo"
            >
              {{ $t('rwa_Deliver') }}
            </button>
          </div>
        </div>
      </div>

      <!-- 收货方 -->

      <div v-if="productInfo.recipient == this.$store.state.did">
        <!-- 未支付显示去支付 -->
        <div
          class="d-flex flex-row justify-space-between align-center"
          v-if="
            productInfo.deliveryStatus == 0 || productInfo.deliveryStatus == 2
          "
        >
          <button class="editBtn" :class="{ 'sub-dis': isPay }" @click="goEdit">
            {{ $t('edit') }}
          </button>

          <button class="editBtn" :class="{ 'sub-dis': isPay }" @click="goPay">
            {{ $t('orderActionPay') }}
          </button>
        </div>
        <!-- 已支付，已发货，显示确认收货 -->
        <div
          style="
            width: 100%;
            align-items: center;
            justify-content: center;
            display: flex;
          "
          v-if="productInfo.deliveryStatus == 4"
        >
          <button class="saveBtn" @click="subReceipt">
            {{ $t('rwa_confirm_receipt') }}
          </button>
        </div>
      </div>
    </v-card>

    <uComponents ref="ucom"></uComponents>
  </div>
</template>
<script>
import api from '@/api';
export default {
  data: function () {
    return {
      isPay: false,
      trackNumberValue: '',
      newOpen: true,
      closeIcon: require('@/assets/btn_close.png'),
      darkcloseIcon: require('@/assets/darkimage/btn_close1.png'),
      productInfo: {},
      rwaNftDetail: {},
      imgUrl: null,
    };
  },
  props: {
    productDetail: {
      type: Object,
    },
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted() {
    this.getDeliveryInfo();
    this.getRwaDetail();
  },
  methods: {
    async getRwaDetail() {
      let params = {
        nftAddress: this.productDetail.nftAddress,
        serialNumber: this.productDetail.serialNumber,
      };
      let result = await api.home.rwaNftinfoDetail(params);
      this.rwaNftDetail = result.data;
      if (this.rwaNftDetail.imgUrl) {
        this.imgUrl = this.getImageIpfs(this.rwaNftDetail.imgUrl);
      }
    },
    async getDeliveryInfo() {
      let params = {
        nftAddress: this.productDetail.nftAddress,
        nftId: this.productDetail.nftId,
      };
      let result = await api.home.getDeliveryInfo(params);
      this.productInfo = result.data;
      if (this.productInfo) {
        this.trackNumberValue = this.productInfo.deliveryNumber;
      }
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return this.$t('orderStateUnpay');
        case 1:
          return this.$t('state_progress');
        case 2:
          return this.$t('failed');
        case 3:
          return this.$t('rwa_todeliverd');
        case 4:
          return this.$t('rwa_Delivering');
        case 5:
          return this.$t('rwa_Receievd');

        default:
          return this.$t('rwa_Redeemable');
      }
    },
    goEdit() {
      console.log('wxl ----- 88888 edit');
      this.$emit('getOpen', false, 'goEdit');
    },
    async goPay() {
      this.isPay = true;
      let result = await this.$wallet.transfer(
        this.productInfo.feeTokenAddress,
        this.productInfo.deliveryfee,
        this.productInfo.sender
      );
      console.log('支付结果', result);
      console.log('支付结果hash==', result.hash);
      if (!result.hash) {
        this.isPay = false;
        this.$toast('error', this.$t('requireFailed'));
        return;
      } else {
        this.$toast('success', this.$t('success'));
        // 调用pending.do

        let params = {
          nftAddress: this.productDetail.nftAddress,
          nftId: this.productDetail.nftId,
          orderId: this.productInfo.id,
          hash: result.hash,
        };
        let pendingResult = await api.home.feePayPending(params);
        if (!pendingResult.success) {
          this.$toast('error', pendingResult.msg);
          return;
        } else {
          this.getDeliveryInfo();
        }
      }
    },
    async subCommit() {
      try {
        debugger;
        let params = {
          nftAddress: this.productDetail.nftAddress,
          nftId: this.productDetail.nftId,

          orderId: this.productInfo.id,
          deliveryNumber: this.trackNumberValue,
        };
        let result = await api.home.addDelivery(params);
        if (result.success) {
          this.$toast('success', this.$t('success'));

          setTimeout(() => {
            this.$emit('getOpen', false);
          }, 1500);
        } else {
          this.$toast('error', this.$t('requireFailed'));
        }
        console.log('subCommit');
      } catch (error) {
        this.$toast('error', this.$t('requireFailed'));
      }
    },
    async subFahuo() {
      debugger;
      try {
        let params = {
          nftAddress: this.productDetail.nftAddress,
          nftId: this.productDetail.nftId,
          orderId: this.productInfo.id,
          deliveryNumber: this.trackNumberValue,
        };
        let result = await api.home.addDelivery(params);
        if (result.success) {
          debugger;
          this.$toast('success', this.$t('success'));
          setTimeout(() => {
            this.$emit('getOpen', false);
          }, 1500);
        } else {
          this.$toast('error', this.$t('requireFailed'));
        }
        console.log('subCommit');
      } catch (error) {
        this.$toast('error', this.$t('requireFailed'));
      }
    },
    async subReceipt() {
      try {
        let params = {
          orderId: this.productInfo.id,
          nftAddress: this.productDetail.nftAddress,
          nftId: this.productDetail.nftId,
        };
        let result = await api.home.confirmReceipt(params);
        if (result.success) {
          this.$toast('success', this.$t('success'));
          setTimeout(() => {
            this.$emit('getOpen', false);
            window.eventBus.$emit('refreshPage');
            window.eventBus.$emit('collectionPay');
          }, 1500);
        } else {
          this.$toast('error', this.$t('requireFailed'));
        }
        console.log('subCommit');
      } catch (error) {
        this.$toast('error', this.$t('requireFailed'));
      }
    },
    onCopyClick(str) {
      if (!str) {
        return;
      }

      try {
        // 使用 Clipboard API
        if (navigator.clipboard && window.isSecureContext) {
          navigator.clipboard
            .writeText(str)
            .then(() => {
              this.$toast('success', this.$t('copt_success'));
            })
            .catch(() => {
              this.fallbackCopyText(str);
            });
        } else {
          // 回退方案
          this.fallbackCopyText(str);
        }
      } catch (err) {
        this.$toast('error', this.$t('copy_failed'));
      }
    },
    fallbackCopyText(text) {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.position = 'fixed';
      textArea.style.left = '-9999px';
      textArea.style.top = '0';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        this.$toast('success', this.$t('copt_success'));
      } catch (err) {
        this.$toast('error', this.$t('copy_failed'));
      }

      document.body.removeChild(textArea);
    },
    closeDialog() {
      this.open = false;
      this.$emit('getOpen', this.open);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 445px;
  width: 550px;
  padding: 30px 50px;

  .title {
    font-family: Helvetica;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #270645;
  }
  .imageUrl {
    width: 106px;
    height: 106px;
    border-radius: 5px;
  }
  .collectName {
    width: 78%;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #270645;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .productName {
    width: 78%;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #270645;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .copyBtn {
    width: 150px;
    padding: 0 10px;
    height: 33px;
    background-color: #ffffff;
    border-radius: 16px;
    border: solid 1px #7800f4;
    font-family: Helvetica;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #7800f4;
  }
  .description {
    width: 100%;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #766983;
  }
  .saveBtn {
    font-family: Helvetica;
    width: 400px;
    height: 51px;
    background-color: #7800f4;
    border-radius: 25px;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #ffffff;
    margin-top: 44px;
  }
  .editBtn {
    font-family: Helvetica;
    width: 48%;
    height: 51px;
    background-color: #7800f4;
    border-radius: 25px;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #ffffff;
    margin-top: 44px;
  }
  .receiptName {
    font-family: Helvetica;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 25px;
    letter-spacing: 0px;
    color: #270645;
  }
  .number {
    font-family: Helvetica;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #270645;
    margin-top: 25px;
    margin-bottom: 10px;
  }
  .save {
    padding: 0 15px;
    height: 33px;
    background-color: #ffffff;
    border-radius: 16px;
    border: solid 1px #7800f4;
    font-family: Helvetica;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #7800f4;
  }
  .unsave {
    background-color: #666666;
  }
  .box {
    position: relative;
    .copyProfile {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 15px;
      height: 15px;
    }
    &::v-deep .v-input__slot {
      height: 46px !important;
      min-height: 46px;
    }
    &::v-deep .v-text-field input {
      max-width: 90%;
      height: 46px !important;
    }
  }
  .font-14 {
    font-family: Helvetica;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 28px;
    letter-spacing: 1px;
    color: #270645;
  }

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }
  .mainContain {
    margin-top: 46px;
    .title-23 {
      font-family: Helvetica;
      font-size: 23px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 3px;
      letter-spacing: 0px;
      color: #270645;
    }
    .name {
      font-family: Helvetica;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 30px;
      letter-spacing: 0px;
      color: #270645;
      text-align: center;
    }
    .btn {
      margin-top: 35px;
      width: 221px;
      height: 51px;
      background-color: #7800f4;
      border-radius: 25px;
      font-family: Helvetica;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #ffffff;
    }
    .notice {
      width: 52px;
      height: 52px;
    }
  }

  &.mobile {
    .collectName {
      white-space: normal;
    }
  }
}

.sub-dis {
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(#766983, #766983),
    linear-gradient(#270645, #270645) !important;
  background-blend-mode: normal, normal;
  border-radius: 25px;
  opacity: 0.9;
}
.sub-dis::after {
  content: '';
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 20px;
}
</style>
