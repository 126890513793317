<template>
  <div class="foot">
    <v-footer
      app
      absolute
      class="pa-0 pb-15 pb-lg-5 d-flex flex-column"
      color="#7800f4"
      :class="{ darkBackground: this.$vuetify.theme.dark }"
      :elevation="0"
      :height="height"
    >
      <v-container
        class="d-flex flex-column flex-lg-row py-0 px-10 px-lg-10 px-md-10 mt-lg-8"
        style="max-width: 2600px"
      >
        <template v-if="isMobile">
          <div class="mobilefoot">
            <div style="margin-top: 30px"></div>
            <div class="CommunityTitle">{{ $t('footer_community') }}</div>
            <div class="d-flex justify-space-around mt-8" style="width: 100%">
              <a
                :href="discodeurl"
                target="_blank"
                @click="mediaClick('discord')"
              >
                <img
                  src="@/assets/icons/icon_discord.png"
                  style="width: 24px; height: 24px"
                />
              </a>
              <a
                :href="`https://${mediumurl}`"
                target="_blank"
                @click="mediaClick('medium')"
              >
                <img
                  src="@/assets/icons/icon_medium.png"
                  class="ml-8"
                  style="width: 24px; height: 24px"
                />
              </a>
              <a
                :href="twitterurl"
                target="_blank"
                @click="mediaClick('twitter')"
              >
                <img
                  src="@/assets/icons/icon_twitter.png"
                  class="ml-8"
                  style="width: 24px; height: 24px"
                />
              </a>
              <a
                :href="teleurl"
                target="_blank"
                @click="mediaClick('telegram')"
              >
                <img
                  src="@/assets/icons/icon_telegram.png"
                  class="ml-8"
                  style="width: 24px; height: 24px"
                />
              </a>
              <a
                :href="`mailto:${mailurl}`"
                target="_blank"
                @click="mediaClick('email')"
              >
                <img
                  src="@/assets/icons/icon_mail.png"
                  style="width: 24px; height: 24px"
                  class="ml-8"
                />
              </a>
            </div>
            <div class="CommunityTitle mt-12">
              {{ $t('footer_newsletter') }}
            </div>
            <div class="sub d-flex flex-column mt-6 mb-5">
              <input
                id="email"
                type="email"
                v-model="value"
                class="form-control mobileemail"
                :placeholder="this.$t('footer_input_des')"
              />
              <button
                class="Subscribe ml-lg-3 ml-0 mt-lg-0 mt-3"
                @click="subscribe"
              >
                {{ $t('footer_subscribe') }}
              </button>
            </div>
            <div @click="moreClick('about')">
              <a
                href="https://www.uptick.network/#/"
                target="_blank"
                class="aboutTitle"
                >{{ $t('FooterAbout') }}</a
              >
            </div>
            <div @click="moreClick('blog')">
              <a
                href="https://blog.uptickproject.com/"
                target="_blank"
                class="aboutTitle mt-8"
                >{{ $t('FooterBlog') }}</a
              >
            </div>
            <a
              @click="moreClick('faq')"
              href="https://upticknft.gitbook.io/uptick-mainnet-marketplace-guide/explore-nfts/where"
              target="_blank"
              class="aboutTitle"
              >{{ $t('footer_faq') }}</a
            >
            <div class="mobileOptions">
              <a @click="todownload">More Download Options</a>
            </div>
            <div class="faucet" @click="toFaucet">
              {{ $t('request_token') }} >
            </div>
            <div class="mobileLanguage mt-2" @click.stop="languageChange">
              {{ currentLanguage }}
              <v-icon
                class="ml-2"
                color="rgba(255,255,255)"
                v-if="languageValue"
                >mdi-menu-up</v-icon
              >
              <v-icon class="ml-2" color="rgba(255,255,255)" v-else
                >mdi-menu-down</v-icon
              >
            </div>
            <div class="mobileLanguageList" v-if="languageValue">
              <div class="item">
                <div class="list" v-for="(item, index) in lang" :key="index">
                  <div class="name" @click="setLanguage(item.value)">
                    {{ item.label }}
                  </div>
                </div>
              </div>
            </div>

            <Logo height="43px" class="mt-10" />
            <div class="mobilecopyright">
              Copyright ©2019-{{ fullYear }} Uptick Network. All rights
              reserved.
            </div>
          </div>
        </template>
        <template v-else>
          <div
            class="d-flex flex-row justify-space-between"
            style="width: 100%"
          >
            <div class="footer d-flex flex-column">
              <div class="community">
                <div class="CommunityTitle">{{ $t('footer_community') }}</div>
                <div class="icon mt-6">
                  <a
                    :href="discodeurl"
                    target="_blank"
                    @click="mediaClick('discord')"
                  >
                    <img
                      src="@/assets/icons/icon_discord.png"
                      style="width: 30px; height: 30px"
                    />
                  </a>
                  <a
                    :href="`https://${mediumurl}`"
                    target="_blank"
                    @click="mediaClick('medium')"
                  >
                    <img
                      src="@/assets/icons/icon_medium.png"
                      class="ml-8"
                      style="width: 30px; height: 30px"
                    />
                  </a>
                  <a
                    :href="twitterurl"
                    target="_blank"
                    @click="mediaClick('twitter')"
                  >
                    <img
                      src="@/assets/icons/icon_twitter.png"
                      class="ml-8"
                      style="width: 30px; height: 30px"
                    />
                  </a>
                  <a
                    :href="teleurl"
                    target="_blank"
                    @click="mediaClick('telegram')"
                  >
                    <img
                      src="@/assets/icons/icon_telegram.png"
                      class="ml-8"
                      style="width: 30px; height: 30px"
                    />
                  </a>
                  <a
                    :href="`mailto:${mailurl}`"
                    target="_blank"
                    @click="mediaClick('email')"
                  >
                    <img
                      src="@/assets/icons/icon_mail.png"
                      style="width: 30px; height: 30px"
                      class="ml-8"
                    />
                  </a>
                </div>
              </div>
              <div class="logo d-flex flex-row">
                <Logo height="50px" width="130px" />
                <div class="copyright">
                  Copyright ©2019-{{ fullYear }} Uptick Network. All rights
                  reserved.
                </div>
              </div>
              <!-- <div class="about ml-4 d-flex flex-column">
            <a href="https://www.uptick.network/#/" target="_blank" class="aboutTitle">{{$t('FooterAbout')}}</a>
            <a href="https://blog.uptickproject.com/" target="_blank" class="aboutTitle mt-8">{{$t('FooterBlog')}}</a>
             <div class="aboutTitle mt-8">{{$t('footer_faq')}}</div>
            </div>  -->
            </div>
            <div class="d-flex flex-column">
              <div class="CommunityTitle">{{ $t('footer_newsletter') }}</div>
              <div class="sub d-flex flex-row mt-5">
                <input
                  id="email"
                  type="email"
                  v-model="value"
                  class="form-control email"
                  :placeholder="this.$t('footer_input_des')"
                />
                <button class="Subscribe ml-4" @click="subscribe">
                  {{ $t('footer_subscribe') }}
                </button>
              </div>
              <div class="about mt-7 d-flex flex-row">
                <a
                  @click="moreClick('about')"
                  href="https://www.uptick.network/#/"
                  target="_blank"
                  class="aboutTitle"
                  >{{ $t('FooterAbout') }}</a
                >
                <a
                  @click="moreClick('blog')"
                  href="https://blog.uptickproject.com/"
                  target="_blank"
                  class="aboutTitle"
                  >{{ $t('FooterBlog') }}</a
                >
                <a
                  @click="moreClick('faq')"
                  href="https://upticknft.gitbook.io/uptick-chain-marketplace-help-center/setup/how-to-create-an-uptick-account"
                  target="_blank"
                  class="aboutTitle"
                  >{{ $t('footer_faq') }}</a
                >
                <!-- <div class="aboutTitle ">{{$t('footer_faq')}}</div> -->
                <div class="language" @click.stop="languageChange">
                  {{ currentLanguage }}
                  <v-icon
                    class="ml-2"
                    color="rgba(255,255,255)"
                    v-if="languageValue"
                    >mdi-menu-up</v-icon
                  >
                  <v-icon class="ml-2" color="rgba(255,255,255)" v-else
                    >mdi-menu-down</v-icon
                  >
                </div>
                <div class="languageList" v-if="languageValue">
                  <div class="item">
                    <div
                      class="list"
                      v-for="(item, index) in lang"
                      :key="index"
                    >
                      <div class="name" @click="setLanguage(item.value)">
                        {{ item.label }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <LangSelect class="ml-lg-2 langselect" style="z-index: 202;" :class="{mobileLang:isMobile}" :isDrak="$vuetify.theme.dark"/>  -->
              </div>
              <div class="faucet mt-5" @click="toFaucet" v-if="isShowFeacet">
                {{ $t('request_token') }} >
              </div>
            </div>
          </div>

          <uComponents ref="ucom"></uComponents>
        </template>
      </v-container>
    </v-footer>
  </div>
</template>

<script lang="js">
import Logo from "@/components/footLogo/index.vue";
 import LangSelect from './components/LangSelect.vue';
import api from "@/api";

  export default {
    name: "Footer",
    components: { Logo,LangSelect },
    props: {},
    data: function() {
      return {
        currentLanguage:'',
        lang:[
        { label: 'English', value: 'en' },
        { label: '中文', value: 'zh' },
        { label: '日本語', value: 'ja' },
        { label: '코리아', value: 'ko' },
        // { label: 'Português', value: 'pt' },
      ],
        languageValue:false,
        height:210,
        isShowFeacet:true,
        value: '',
        fullYear:"",
       discodeurl:"https://discord.com/invite/teqX78VZUV",
        mediumurl: "uptickproject.medium.com",
        twitterurl:'https://twitter.com/Uptickproject',
        teleurl:'https://t.me/uptickproject',
        mailurl:'hello@uptickproject.com'
      };
    },
    computed: {
      isMobile: function() {
        return this.$vuetify.breakpoint.mobile;
      }
    },
  mounted() {
      this.fullYear = new Date().getFullYear()
         let currentChain = `${this.$env.VUE_APP_MARKET_TYPE}`
              if(currentChain == 'POLYGON' || currentChain == 'BSC' ||currentChain ==  'ARBITRUM')
              {
               this.isShowFeacet = false
              }else{
                this.isShowFeacet = true
          }
       window.addEventListener("click", this.clickOther);
       window.eventBus.$on("LangChange", this.onLangChange);
     let language =  localStorage.getItem("KEY_LANG");
     if(!language){
       this.setLanguage('en')
     }else{
        this.setLanguage(language)
     }
      if(this.isMobile){
        this.height = 575
      }else{
        this.height = 210
      }

    },
    methods: {
      mediaClick(name){
         this.$gtag.event('底部导航-'+name, { 'event_category': 'Click', 'event_label': name })
        console.log("mediaClick",name);
      },
      moreClick(name){
 this.$gtag.event('底部导航-'+name, { 'event_category': 'Click', 'event_label': name })
      },
      clickOther(){
         this.languageValue =false
      },

      onLangChange(lang){
        console.log("footerLang",lang);
         this.lang.forEach(element => {
          if(element.value == lang)
          this.currentLanguage = element.label
        });
      },
       setLanguage(language) {
        //  this.currentLanguage = this.lang[]
        this.lang.forEach(element => {
          if(element.value == language)
          this.currentLanguage = element.label
        });
        document.documentElement.lang = language;
        localStorage.setItem("KEY_LANG", language);
        this.$i18n.locale = language;
        this.$vuetify.lang.current = language;
        window.eventBus.$emit("LangChange", language);
        this.languageValue =false
      },
      languageChange(){
        this.$gtag.event('底部导航-多语言切换', { 'event_category': 'Click', 'event_label': '多语言切换' })
        this.languageValue = !this.languageValue
      },
      toFaucet(){
        this.$gtag.event('底部导航-Request UPTICK for Gas', { 'event_category': 'Click', 'event_label': 'Request UPTICK for Gas' })
        if(!this.$store.state.did){
          	window.eventBus.$emit("isWallet");
        }else{
          this.$router.push({ name: "Faucet" });


        }


      },
    async  subscribe(){
        this.$gtag.event('底部导航-Newsetter', { 'event_category': 'Click', 'event_label': 'Newsetter' })
        let params = {
          email: this.value,
					channel:3
        }
      let res =  await api.home.Newsletter(params);
      if(res.success){
         this.$toast("success", this.$t("success"))
         this.value = ''
      }else{
         this.$toast("error", res.msg)
      }

      },
	  todownload(){
		  this.$router.push({ name: "Download" });
	  },

      openmedium() {
        window.open().opener = null;
        window.location.href = this.url;
      },
      opentwitter() {
        window.open().opener = null;
        window.location.href = this.url;
      },
      opentelegram() {
        window.open().opener = null;
        window.location.href = this.url;
      },
      toHelp(){
        this.$router.push({name:'HelpCenter'})
      },
      toPrivacy(){
        if(this.$vuetify.lang.current == 'en'){
          this.$router.push({name:'Privacy'})
        }else if(this.$vuetify.lang.current == 'zh'){
          this.$router.push({name:'Privacyzh'})
        }else if(this.$vuetify.lang.current == 'ja'){
          this.$router.push({name:'Privacy'})
        }else if(this.$vuetify.lang.current == 'ko'){
          this.$router.push({name:'Privacy'})
        }else if(this.$vuetify.lang.current == 'pt'){
          this.$router.push({name:'Privacy'})
        }
      },
      toTerms(){

          if(this.$vuetify.lang.current == 'en'){
          this.$router.push({name:'Term'})
        }else if(this.$vuetify.lang.current == 'zh'){
          this.$router.push({name:'Termzh'})
        }else if(this.$vuetify.lang.current == 'ja'){
          this.$router.push({name:'Term'})
        }else if(this.$vuetify.lang.current == 'ko'){
          this.$router.push({name:'Term'})
        }else if(this.$vuetify.lang.current == 'pt'){
          this.$router.push({name:'Term'})
        }

      }
    }
  };
</script>

<style lang="scss" scoped>
.mobileLanguage {
  cursor: pointer;
  margin-top: -4px;
  font-family: Helvetica;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #ffffff;
}
.mobileLanguageList {
  position: absolute;
  bottom: 105px;
  left: 10px;
  width: 115px;
  height: 185px;
  background-image: linear-gradient(#ffffff, #ffffff),
    linear-gradient(#ffffff, #ffffff);
  background-blend-mode: normal, normal;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  .item {
    margin: 26px 24px;
    .name {
      cursor: pointer;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 35px;
      letter-spacing: 0px;
      color: #270645;
    }
  }
}
.about {
  position: relative;
  .language {
    cursor: pointer;
    margin-top: -4px;
    font-family: Helvetica;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #ffffff;
  }
  .languageList {
    position: absolute;
    bottom: 27px;
    right: 27px;
    width: 115px;
    height: 185px;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#ffffff, #ffffff);
    background-blend-mode: normal, normal;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
    .item {
      margin: 26px 24px;
      .name {
        cursor: pointer;
        font-family: Helvetica;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 35px;
        letter-spacing: 0px;
        color: #270645;
      }
    }
  }
}

.mobilecopyright {
  min-width: 340px;
  font-family: Helvetica;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 15px;
  letter-spacing: 0px;
  color: #ffffff;
}

.mobileOptions {
  a {
    font-family: Helvetica;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 15px;
    letter-spacing: 0px;
    color: #ffffff;
  }
}

.right {
  margin-left: 200px;
  .contant {
    margin-top: 27px;
    img {
      width: 110px;
      height: 110px;
    }
    .download {
      width: 138px;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #ffffff;
      margin-bottom: 10px;
    }
    a {
      width: 150px;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }
}
.aboutTitle {
  text-decoration: none;
  font-family: Helvetica;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 15px;
  letter-spacing: 0px;
  color: #ffffff;
  width: 120px;
}
.logo {
  margin-top: 10px;
  text-align: center;
  align-items: center;
  .copyright {
    margin-left: 22px;
    min-width: 416px;
    font-family: Helvetica;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 15px;
    letter-spacing: 0px;
    color: #ffffff;
  }
}
.Subscribe {
  width: 111px;
  height: 41px;
  background-image: linear-gradient(
    90deg,
    #b633f1 0%,
    #dc539c 50%,
    #ff704a 100%
  );
  border-radius: 5px;
  font-family: Helvetica;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 15px;
  letter-spacing: 0px;
  color: #ffffff;
}
.email:focus {
  color: #212529;
  background-color: #fff;
  outline: 0;
  box-shadow: none;
  border: 1px solid #191b77;
  outline: none;
}
.email {
  width: 340px;
  height: 41px;
  padding: 10px 0.75rem;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 15px;
  letter-spacing: 0px;
  color: #666666;
  background-color: #ffffff;
  border-radius: 10px;
  border: solid 1px #9330e7;
}
.mobileemail {
  width: 320px;
  height: 41px;
  padding: 10px 0.75rem;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 15px;
  letter-spacing: 0px;
  color: #666666;
  background-color: #ffffff;
  border-radius: 10px;
  border: solid 1px #9330e7;
}
.mobileemail:focus {
  color: #212529;
  background-color: #fff;
  outline: 0;
  box-shadow: none;
  border: 1px solid #191b77;
  outline: none;
}

.CommunityTitle {
  font-family: Helvetica;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #ffffff;
}
.langselect {
  &::v-deep .v-chip {
    box-shadow: none;
    border: none;
    margin-bottom: 2px;
    font-weight: bold;
  }
}
.faucet {
  font-family: Helvetica;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  cursor: pointer;
}
.fotnav {
  max-width: 1220px;
  margin: 25px auto 16px;

  .left {
    font-family: Helvetica;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: 0px;
    color: #270645;
    .pointer {
      cursor: pointer;
    }
  }
  .right {
    font-family: Helvetica;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: 0px;
    color: #270645;
    text-align: right;
    .pointer {
      cursor: pointer;
    }
  }
}
.fotmobile {
  width: 320px;
  margin: 25px auto 16px;

  .left {
    font-family: Helvetica;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: 0px;
    color: #270645;
    .pointer {
      cursor: pointer;
    }
  }
  .right {
    font-family: Helvetica;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: 0px;
    color: #270645;
    text-align: right;
    .pointer {
      cursor: pointer;
    }
  }

  .base-box {
    width: 100%;
    // border: solid 1px red;
    margin-bottom: -40px;
  }

  .top-item {
    // width: 100%;
    // margin: 0,auto;
    font-weight: 600;
    margin-bottom: 20px;
    // border: solid 1px black;
    text-align: center;
  }

  .bottom-item {
    // width: 100%;
    // margin: 0,auto;
    // font-weight: 600;
    margin-bottom: 20px;
    // border: solid 1px black;
    text-align: center;
  }

  .last-item {
    text-align: center;
  }
}
</style>
