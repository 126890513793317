<template>
  <div :class="{ mobile: isMobile }">
    <div
      class="card-List"
      :class="{
        clearDisplay: owerfrom === 'personalPage' || type == 'collect',
      }"
    >
      <div
        class="card-box"
        :class="{ 'card-home': showModel == 'home' }"
        v-for="(item, i) in list"
        :key="i"
        @click="toPage(item)"
      >
        <Card
          :isMinPrice="isMinPrice"
          :value="item"
          :type="type"
          :isCreateCheck="item.checked"
          :owerfrom="item.useType !== 1 ? owerfrom : auction"
        />
        <v-checkbox
          v-if="isShowCheckbox == 'isCreateCheck'"
          class="check"
          color="#270645"
          :key="i"
          :value="item.checked"
          @click="childcheckboxChange(i, item)"
          hide-details
          readonly
        ></v-checkbox>
      </div>
    </div>
    <loading :isShowLoading="isShowLoading"></loading>
  </div>
</template>

<script lang="js">
  import Card from "@/components/workCard/Card.vue";
  import Loading from "@/components/loading.vue";

  export default {
    name: "CardList",
    components: { Loading, Card },
    props: {
      list: {
        type: Array,
        default: () => []
      },
      type: String,
      isShowLoading: {
        type: Boolean
      },
       isShowCheckbox:{
         type:String,
      },

      owerfrom: String,
      isMinPrice:String,
      showModel:String,
      checkAllCard:{
         type:Boolean
      }
    },
    data: function() {
      return {
        auction: "auction"
      };
    },
    computed: {
		isMobile: function () {
			return this.$vuetify.breakpoint.mobile;
      },

   },
    watch:{
checkAllCard(value){
  if(value){
      this.list.forEach((ele) => {
      ele.checked = true;
    });
  }
   this.nftList = this.list.filter(item=>item.checked)
      this.$emit("getCardList",this.nftList);
  this.$forceUpdate()
}
  },
    mounted() {

      this.list.forEach((ele) => {
      ele.checked = false;
    });
    },
    methods: {
      toPage(item) {
      },
        childcheckboxChange(i, item) {
this.list[i].checked = !this.list[i].checked;
       this.nftList = this.list.filter(item=>item.checked)
       if(this.nftList.length != this.list.length)
       {
this.$emit("clickCheck",false);
       }else{

          this.$emit("clickCheck",true);
       }
       this.$emit("getCardList",this.nftList);
      this.$forceUpdate()
    },
    }
  };
</script>

<style lang="scss" scoped>
.card-List {
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  // grid-gap: 32px;
  .card-box {
    //  min-width: 0 to fix grid && white-space
    min-width: 0;
    display: inline-block;
    position: relative;
    width: 18%;

    margin-bottom: 24px;
    margin-right: 2.5%;
    // justify-content: space-between;
    &:nth-child(5n) {
      margin-right: 0;
    }

    .check {
      // z-index: 200;
      position: absolute;
      top: -10px;
      left: 10px;
      &::v-deep .v-input__control .v-input__slot .v-label {
        font-family: Helvetica;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        // line-height: 54px;
        letter-spacing: 0px;
        color: #270645;
      }
    }
  }
  .card-home {
    width: 24%;
    margin-right: 1%;
  }
  @media screen and (max-width: 975px) {
    grid-template-columns: repeat(1, 1fr);
    .card-box {
      width: 100%;

      &:nth-child(2n) {
        margin-right: 0 !important;
      }
      &:nth-child(5n) {
        // margin-right: 30px;
      }
    }
    .v-card {
      width: unset;
    }
  }

  @media screen and (min-width: #{map-get($grid-breakpoints, 'md')}) {
    grid-template-columns: repeat(2, 1fr);
    .v-card {
      // width: unset;
    }
  }
  // @media screen and (max-width: 1220px) {
  //    .card-box {
  //       width: 46%;
  //       margin-right: 20px !important;
  //       &:nth-child(2n) {
  //          margin-right: 0 !important;
  //       }
  //       &:nth-child(5n) {
  //          // margin-right: 30px;
  //       }
  //    }
  //    grid-template-columns: repeat(3, 1fr);
  // }
  @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.clearDisplay {
  display: block !important;
}

.mobile {
  .card-List {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    margin-top: 20px;
    // display: unset;
  }
}
</style>
