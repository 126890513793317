<template>
  <v-list nav dense :light="false" style="z-index: 10">
    <v-list-item-group mandatory color="#fff" v-if="type == 'head'">
      <v-list-item :to="{ name: 'Home' }" @click="click('Home')" class="mt-5">
        <v-list-item-content>
          <v-list-item-title
            style="font-size: 20px; line-height: unset"
            v-text="$t('headerNavHome')"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="{ name: 'Marketplace' }"
        @click="click('Marketplace')"
        class="mt-5"
      >
        <v-list-item-content>
          <v-list-item-title
            style="font-size: 20px; line-height: unset"
            v-text="$t('headerNavMarketplace')"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'Rwa' }" @click="click('Rwa')" class="mt-5">
        <v-list-item-content>
          <v-list-item-title
            style="font-size: 20px; line-height: unset"
            v-text="$t('rwas')"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="{ name: 'LaunchPad' }"
        @click="click('LaunchPad')"
        class="mt-5"
      >
        <v-list-item-content>
          <v-list-item-title
            style="font-size: 20px; line-height: unset"
            v-text="$t('nav_launchpad')"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'Drops' }" @click="click('Drops')" class="mt-5">
        <v-list-item-content>
          <v-list-item-title
            style="font-size: 20px; line-height: unset"
            v-text="$t('Drops')"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="toLearnMore" class="mt-5">
        <v-list-item-content>
          <v-list-item-title
            style="font-size: 20px; line-height: unset"
            v-text="$t('nav_learnmore')"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="{ name: 'Promo' }"
        @click="click('Promo')"
        class="mt-5"
        v-if="chain == 'UPTICK'"
      >
        <v-list-item-content>
          <v-list-item-title
            style="font-size: 20px; line-height: unset"
            v-text="$t('headerNavPromo')"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="{ name: 'Download' }"
        @click="click('Download')"
        class="mt-5"
        v-if="chain == 'UPTICK'"
      >
        <v-list-item-content>
          <v-list-item-title
            style="font-size: 20px; line-height: unset"
            v-text="$t('download_app')"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
    <v-list-item-group mandatory color="#fff" v-if="type == 'user'">
      <v-list-item :to="{ name: 'Spotlight' }" @click="click('Spotlight')">
        <img src="@/assets/icons/m_spotlight.png" alt="" />
        <v-list-item-content class="mobileNavTitle">
          <v-list-item-title v-text="$t('showcase_menu')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        :to="{ name: 'PersonalPage' }"
        @click="click('PersonalPage')"
      >
        <img src="@/assets/icons/m_myprofile.png" alt="" />
        <v-list-item-content class="mobileNavTitle">
          <v-list-item-title
            v-text="$t('headerUserCenterNavMyHomePage')"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- <v-list-item :to="{ name: 'MyInfo' }" @click="click">
         <img src="@/assets/icons/m_myprofile.png" alt="">
        <v-list-item-content>
          <v-list-item-title v-text="$t('headerUserCenterNavEditProfile')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-list-item
        :to="{ name: 'PointsCenter' }"
        @click="click('PointsCenter')"
      >
        <img src="@/assets/icons/m_points.png" alt="" />
        <v-list-item-content>
          <v-list-item-title
            v-text="$t('scroe_task_center')"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="user"
        :to="{ name: 'Collect' }"
        @click="click('Collect')"
      >
        <img src="@/assets/icons/m_favorites.png" alt="" />
        <v-list-item-content>
          <v-list-item-title
            v-text="$t('headerUserCenterNavMyFavorites')"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'MyOrders' }" @click="click('MyOrders')">
        <img src="@/assets/icons/m_orders.png" alt="" />
        <v-list-item-content>
          <v-list-item-title
            v-text="$t('headerUserCenterNavMyOrders')"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="{ name: 'MyReferences' }"
        @click="click('MyReferences')"
      >
        <img src="@/assets/icons/m_social.png" alt="" />
        <v-list-item-content>
          <v-list-item-title
            v-text="$t('headerUserCenterNavMyReferences')"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'Message' }" @click="click('Message')">
        <img src="@/assets/icons/m_messages.png" alt="" />
        <v-list-item-content>
          <v-list-item-title v-text="$t('messageMessage')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'Setting' }" @click="click('Setting')">
        <img src="@/assets/icons/m_settings.png" alt="" />
        <v-list-item-content>
          <v-list-item-title
            v-text="$t('headerUserCenterNavSetting')"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="clickLanguage">
        <img src="@/assets/icons/m_language.png" alt="" />
        <v-list-item-content>
          <v-list-item-title
            v-text="$t('headerUserCenterNavLanguage')"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="disConnectClick">
        <img src="@/assets/icons/m_disconnect.png" alt="" />
        <v-list-item-content>
          <v-list-item-title
            v-text="$t('headerUserCenterNavdisConnect')"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>

    <v-dialog v-model="languageOpen" v-if="languageOpen">
      <Language @getOpen="openLanguage"></Language>
    </v-dialog>
  </v-list>
</template>

<script>
import Language from '@/components/popup/language.vue';
export default {
  name: 'DropSelectNav',
  props: {
    value: Boolean,
    type: String,
  },
  components: {
    Language,
  },
  data: function () {
    return {
      user: false,
      languageOpen: false,
      chain: 'UPTICK',
    };
  },
  mounted() {
    // 初始化
    let currentChain = `${this.$env.VUE_APP_MARKET_TYPE}`;
    let currentSymbol = `${this.$env.VUE_APP_ADD_NETWORK_CHAIN_SYMBOL}`;
    if (currentChain == 'BSC') {
      this.chain = 'BNB';
    } else if (currentChain == 'POLYGON') {
      this.chain = 'MATIC';
    } else if (currentChain == 'ARBITRUM') {
      this.chain = currentSymbol;
    }
    const json = localStorage.getItem('key_user');
    if (json) {
      const data = JSON.parse(json);
      this.user = data.user;
    }
  },
  methods: {
    click: function (name) {
      this.$gtag.event('mobile导航切换-' + name, {
        event_category: 'Click',
        event_label: name,
      });
      this.$emit('input', !this.value);
      console.log(',,,,,,,,,', !this.value, this.type);
    },
    disConnectClick() {
      this.$gtag.event('mobile导航切换-disConnect', {
        event_category: 'Click',
        event_label: 'disConnect',
      });
      localStorage.clear();
      sessionStorage.clear();

      this.$store.commit('SET_DID', '');
      window.bscAddress = '';
      this.$router.push({ name: 'Home' });
      window.location.reload();
    },
    clickLanguage() {
      this.$gtag.event('mobile导航切换-language', {
        event_category: 'Click',
        event_label: 'language',
      });
      this.languageOpen = true;
    },
    openLanguage(e) {
      this.languageOpen = e;
      this.$emit('input', !this.value);
    },
    toLearnMore() {
      this.$gtag.event('mobile导航切换-learnMore', {
        event_category: 'Click',
        event_label: 'learnMore',
      });
      window.open('https://www.uptick.network/marketplace.html', '_blank');
      this.$emit('input', !this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.v-list-item.v-item--active {
  background: $primary;
  &::v-deep .v-list-item__title {
    color: #fff;
  }
}
.theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ) {
  color: $primary;
}
</style>
