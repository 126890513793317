import { render, staticRenderFns } from "./rwaPickupDetail.vue?vue&type=template&id=1d83f338&scoped=true"
import script from "./rwaPickupDetail.vue?vue&type=script&lang=js"
export * from "./rwaPickupDetail.vue?vue&type=script&lang=js"
import style0 from "./rwaPickupDetail.vue?vue&type=style&index=0&id=1d83f338&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d83f338",
  null
  
)

export default component.exports