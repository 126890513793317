<template>
  <div
    style="overflow: hidden; height: 90px"
    class="pb-3 cards"
    @mouseleave="leaveMore()"
    :class="{ mobile: isMobile }"
  >
    <v-card-title
      class="text--title px-5 py-3"
      :class="{ darkFont: this.$vuetify.theme.dark }"
      >{{ value.name ? value.name : 'No Name' }}</v-card-title
    >
    <v-card-text style="height: 44px">
      <template
        v-if="
          value.marketType == '1' ||
          value.marketType == null ||
          value.marketType == '3' ||
          value.marketType == '4' ||
          value.marketType == '5'
        "
      >
        <!-- 被禁止/被投诉 -->
        <template v-if="value.assetStatus > 1 || value.contractStatus > 1">
          <div class="notOnsale mt-n3">
            <div
              class="nftInfo d-flex flex-row justify-space-between align-center pt-lg-1"
              v-if="!isShowCard"
            >
              <div class="d-flex flex-row pl-5">
                <img
                  class="mt-lg-n2 mt-n8 ban"
                  src="@/assets/icons/ban.png"
                  width="20px"
                  height="20px"
                  alt=""
                  @mouseenter="moused()"
                  @mouseleave="leave()"
                />
                <div class="explain d-flex flex-row align-center" v-if="show">
                  <span>{{ $t('restricted_nft') }}</span>
                </div>
              </div>
              <div
                class="banDescription"
                :class="{ darkFont: this.$vuetify.theme.dark }"
              >
                {{ $t('restricted_nft') }}
              </div>
            </div>
            <div class="btnbox d-flex flex-row" v-else>
              <button class="Allmore" @click="openMore">...</button>
            </div>
          </div>
          <div class="moreList" v-if="showMore">
            <!-- <div class="title-15" @click.stop="TransferBtn">{{$t('popupNewTransferTransfer')}}</div>
            <div class="title-15" @click.stop="crossChainBrn">{{$t('cross_transfer')}}</div> -->
            <div class="title-15" @click.stop="saleTotalBtn">
              {{ $t('popupSaletotalSalesStatistics') }}
            </div>
            <div
              class="title-15"
              v-if="
                value.assetStatus == 2 && value.creator == this.$store.state.did
              "
              @click.stop="AppealBtn"
            >
              {{ $t('headerUserCenterNavAppeal') }}
            </div>
            <div
              class="title-15"
              v-if="value.isBurn == true"
              @click.stop="burnBtn"
            >
              {{ $t('popupAuctionDefectPriceBurnNFT') }}
            </div>
          </div>
        </template>
        <!-- 进行中 -->
        <template
          v-else-if="
            value.saleStatus == '6' ||
            value.saleStatus == '7' ||
            value.saleStatus == '8' ||
            value.saleStatus == '9' ||
            value.saleStatus == '31'
          "
        >
          <div
            class="d-flex justify-center align-center pt-1 text--info text--secondary-rd"
          >
            {{ $t('workCardInprogress') }}
          </div>
        </template>
        <!-- 已上架，显示下架按钮 -->
        <template v-else-if="value.listAmount > 0">
          <div class="notOnsale mt-n3">
            <div
              class="nftInfo d-flex justify-space-between align-center"
              v-if="!isShowCard"
            >
              <div class="d-flex flex-row pl-5">
                <div>
                  <v-img
                    :src="getChainByName(value.tokenType).img"
                    alt="logo"
                    width="22px"
                    height="22px"
                  />
                </div>
                <div
                  class="ml-2 text--number text--primary-rd"
                  style="line-height: 22px; font-weight: bold !important"
                  :class="{ darkFont: this.$vuetify.theme.dark }"
                >
                  {{ value.marketPrice }}
                </div>
              </div>
              <div>
                <div class="title-13">{{ $t('Best_offer') }}</div>
                <div
                  class="title-13"
                  v-if="value.offerDto && value.offerDto.orderPrice"
                >
                  {{ value.offerDto.orderPrice }}
                  {{ value.offerDto.payTokenType }}
                </div>
                <div class="title-13" v-else>-</div>
              </div>
            </div>
            <div class="btnbox d-flex flex-row" v-else>
              <button
                class="rd"
                @click.stop="EndSaleBtn"
                :class="{ darkFont: $vuetify.theme.dark }"
              >
                {{ $t('popupSoldoutEndsale') }}
              </button>
              <button class="more" @click="openMore">...</button>
            </div>
          </div>
          <div class="moreList" v-if="showMore">
            <div class="title-15" @click.stop="saleTotalBtn">
              {{ $t('popupSaletotalSalesStatistics') }}
            </div>
            <!-- <div
              class="title-15"
              v-if="
               value.nftType== 'ERC1948' &&  !value.deliveryOrder &&value.amount>0 
              "
              @click.stop="toOpenConfirmPickUpFrom"
            >
            {{$t('rwa_redeem')}}
            </div> -->
            <div
              class="title-15"
              v-if="
                value.nftType == 'ERC1948' &&
                value.deliveryOrder &&
                value.deliveryOrder > 2
              "
              @click.stop="toOpenPickUpDetail"
            >
              {{ $t('rwa_redeem_detail') }}
            </div>
            <div
              class="title-15"
              v-if="
                value.assetStatus == 2 && value.creator == this.$store.state.did
              "
              @click.stop="AppealBtn"
            >
              {{ $t('headerUserCenterNavAppeal') }}
            </div>
            <div
              class="title-15"
              v-if="value.isBurn == true"
              @click.stop="burnBtn"
            >
              {{ $t('popupAuctionDefectPriceBurnNFT') }}
            </div>
          </div>
        </template>
        <!-- 已下架，显示上架按钮 -->
        <template v-else-if="value.listAmount == 0 && value.amount > 0">
          <div class="notOnsale mt-lg-n3">
            <div class="nftInfo" v-if="!isShowCard">
              <div class="title-13">{{ $t('Best_offer') }}</div>
              <div
                class="title-13"
                v-if="value.offerDto && value.offerDto.orderPrice"
              >
                {{ value.offerDto.orderPrice }}
                {{ value.offerDto.payTokenType }}
              </div>
              <div class="title-13" v-else>-</div>
            </div>
            <div
              class="btnbox d-flex flex-row mt-n3"
              v-else-if="isShowCard && value.nftId != 0"
            >
              <button
                class="rd"
                @click.stop="putOnSaleBtn"
                :class="{ darkFont: $vuetify.theme.dark }"
              >
                {{ $t('popupMarketStartSale') }}
              </button>
              <button class="more" @click="openMore">...</button>
            </div>
          </div>
          <div class="moreList" v-if="showMore && value.nftId != 0">
            <div
              class="title-15"
              v-if="
                value.listAmount == 0 &&
                value.amount > 0 &&
                value.fileType != 'MP4'
              "
              @click="setAvatar(value)"
            >
              {{ $t('Setavatar') }}
            </div>
            <div class="title-15" @click.stop="TransferBtn">
              {{ $t('popupNewTransferTransfer') }}
            </div>
            <div
              class="title-15"
              v-if="value.nftType != 'ERC1948'"
              @click.stop="crossChainBrn"
            >
              {{ $t('cross_transfer') }}
            </div>
            <div class="title-15" @click.stop="saleTotalBtn">
              {{ $t('popupSaletotalSalesStatistics') }}
            </div>
            <div
              class="title-15"
              v-if="
                value.nftType == 'ERC1948' &&
                !value.deliveryOrder &&
                value.amount > 0
              "
              @click.stop="toOpenConfirmPickUpFrom"
            >
              {{ $t('rwa_redeem') }}
            </div>
            <div
              class="title-15"
              v-if="
                value.nftType == 'ERC1948' &&
                value.deliveryOrder &&
                value.deliveryOrder.recipient == value.owner
              "
              @click.stop="toOpenPickUpDetail"
            >
              {{ $t('rwa_redeem_detail') }}
            </div>
            <div
              class="title-15"
              v-if="
                value.assetStatus == 2 && value.creator == this.$store.state.did
              "
              @click.stop="AppealBtn"
            >
              {{ $t('headerUserCenterNavAppeal') }}
            </div>
            <div
              class="title-15"
              v-if="value.isBurn == true"
              @click.stop="burnBtn"
            >
              {{ $t('popupAuctionDefectPriceBurnNFT') }}
            </div>
          </div>
        </template>
        <!-- 售完状态 -->
        <template v-else-if="value.amount == 0">
          <div class="notOnsale mt-lg-n3">
            <div
              class="nftInfo d-flex flex-row justify-space-between align-center pt-lg-3"
              v-if="!isShowCard"
            >
              <div class="d-flex flex-row pl-5">
                <div>
                  <v-img
                    :src="getChainByName(value.tokenType).img"
                    alt="logo"
                    width="22px"
                    height="22px"
                  />
                </div>
                <div
                  class="ml-2 text--number text--primary-rd"
                  style="line-height: 22px;font-weight:bold;!important"
                  :class="{ darkFont: this.$vuetify.theme.dark }"
                >
                  {{ value.marketPrice }}
                </div>
              </div>
              <div
                class="sold_out"
                :class="{ darkFont: this.$vuetify.theme.dark }"
              >
                {{ $t('workCardSoldOut') }}
              </div>
            </div>
            <div class="btnbox d-flex flex-row mt-n3" v-else>
              <button class="Allmore" @click="openMore">...</button>
            </div>
          </div>
          <div class="moreList" v-if="showMore">
            <div class="title-15" @click.stop="saleTotalBtn">
              {{ $t('popupSaletotalSalesStatistics') }}
            </div>
            <div
              class="title-15"
              v-if="
                value.assetStatus == 2 && value.creator == this.$store.state.did
              "
              @click.stop="AppealBtn"
            >
              {{ $t('headerUserCenterNavAppeal') }}
            </div>
            <div
              class="title-15"
              v-if="value.isBurn == true"
              @click.stop="burnBtn"
            >
              {{ $t('popupAuctionDefectPriceBurnNFT') }}
            </div>
          </div>
        </template>
      </template>
      <!-- 拍卖 -->
      <template v-if="value.marketType == '2'">
        <!-- 被禁止/被投诉 -->
        <template v-if="value.assetStatus > 1 || value.contractStatus > 1">
          <div class="notOnsale mt-n3">
            <div
              class="nftInfo d-flex flex-row justify-space-between align-center pt-lg-1"
              v-if="!isShowCard"
            >
              <div class="d-flex flex-row pl-5">
                <img
                  class="mt-lg-n2 mt-n8 ban"
                  src="@/assets/icons/ban.png"
                  width="20px"
                  height="20px"
                  alt=""
                  @mouseenter="moused()"
                  @mouseleave="leave()"
                />
                <div class="explain d-flex flex-row align-center" v-if="show">
                  <span>{{ $t('restricted_nft') }}</span>
                </div>
              </div>
              <div
                class="banDescription"
                :class="{ darkFont: this.$vuetify.theme.dark }"
              >
                {{ $t('restricted_nft') }}
              </div>
            </div>
            <div class="btnbox d-flex flex-row" v-else>
              <button class="Allmore" @click="openMore">...</button>
            </div>
          </div>
          <div class="moreList" v-if="showMore">
            <!-- <div class="title-15" @click.stop="TransferBtn">{{$t('popupNewTransferTransfer')}}</div>
            <div class="title-15" @click.stop="crossChainBrn">{{$t('cross_transfer')}}</div> -->
            <div class="title-15" @click.stop="saleTotalBtn">
              {{ $t('popupSaletotalSalesStatistics') }}
            </div>
            <div
              class="title-15"
              v-if="
                value.assetStatus == 2 && value.creator == this.$store.state.did
              "
              @click.stop="AppealBtn"
            >
              {{ $t('headerUserCenterNavAppeal') }}
            </div>
            <div
              class="title-15"
              v-if="value.isBurn == true"
              @click.stop="burnBtn"
            >
              {{ $t('popupAuctionDefectPriceBurnNFT') }}
            </div>
          </div>
        </template>

        <!-- 进行中 -->
        <template
          v-else-if="
            value.saleStatus == '6' ||
            value.saleStatus == '7' ||
            value.saleStatus == '8' ||
            value.saleStatus == '9'
          "
        >
          <div
            class="d-flex justify-center align-center pt-1 text--info text--secondary-rd"
            :class="{ darkFont: this.$vuetify.theme.dark }"
          >
            {{ $t('workCardInprogress') }}
          </div>
        </template>
        <!-- 活动未开始 -->
        <template
          v-else-if="value.auction && value.auction.auctionStatus == '0'"
        >
          <div class="notOnsale mt-n3">
            <div
              class="nftInfo d-flex flex-row justify-space-between justify-center"
              v-if="!isShowCard"
            >
              <div class="d-flex flex-column pl-5">
                <div
                  class="text--sub-title-left text--secondary-rd mt-1"
                  :class="{ darkFont: this.$vuetify.theme.dark }"
                >
                  {{ $t('popupAuctionStartingBid') }}
                </div>
                <div class="d-flex flex-row">
                  <div>
                    <v-img
                      :src="getChainByName(value.tokenType).img"
                      alt="logo"
                      width="22px"
                      height="22px"
                    />
                  </div>
                  <div
                    class="ml-2 text--number text--primary-rd"
                    style="line-height: 22px;font-weight:bold;!important"
                    :class="{ darkFont: this.$vuetify.theme.dark }"
                  >
                    {{ value.auction.lastBidPrice }}
                  </div>
                </div>
              </div>
              <div class="mt-1">
                <div
                  class="text--sub-title text--secondary-rd mt-1"
                  :class="{ darkFont: this.$vuetify.theme.dark }"
                >
                  {{ $t('popupAuctionStartDate') }}
                </div>
                <div class="mt-1">
                  <div
                    class="text--number text--primary-rd font-weight-medium mt-1"
                    :class="{ darkFont: this.$vuetify.theme.dark }"
                  >
                    {{ hour }}h {{ minutes }}m {{ second }}s
                  </div>
                </div>
              </div>
            </div>
            <div class="btnbox d-flex flex-row" v-else>
              <button
                class="rd"
                @click.stop="AbanBtn"
                v-if="
                  value.auction.lastBidPrice != null && value.auction.isNotBid
                "
                :class="{ darkFont: $vuetify.theme.dark }"
              >
                {{ $t('Withdraw') }}
              </button>
              <button
                class="more"
                v-if="
                  value.auction.lastBidPrice != null && value.auction.isNotBid
                "
                @click="openMore"
              >
                ...
              </button>
              <button class="Allmore" v-else @click="openMore">...</button>
            </div>
          </div>
          <div class="moreList" v-if="showMore">
            <div class="title-15" @click.stop="TransferBtn">
              {{ $t('popupNewTransferTransfer') }}
            </div>
            <!-- <div
              class="title-15"
             
              @click.stop="crossChainBrn"
            >
              {{ $t("cross_transfer") }}
            </div> -->
            <div class="title-15" @click.stop="saleTotalBtn">
              {{ $t('popupSaletotalSalesStatistics') }}
            </div>
            <div
              class="title-15"
              v-if="
                value.assetStatus == 2 && value.creator == this.$store.state.did
              "
              @click.stop="AppealBtn"
            >
              {{ $t('headerUserCenterNavAppeal') }}
            </div>
            <div
              class="title-15"
              v-if="value.isBurn == true"
              @click.stop="burnBtn"
            >
              {{ $t('popupAuctionDefectPriceBurnNFT') }}
            </div>
          </div>
        </template>

        <!-- 拍卖开始 -->
        <template
          v-else-if="
            value.auction.auctionStatus == '1' ||
            value.auction.auctionStatus == '3'
          "
        >
          <div class="notOnsale mt-n3">
            <div
              class="nftInfo d-flex flex-row justify-space-between justify-center"
              v-if="!isShowCard"
            >
              <div class="d-flex flex-column pl-5">
                <div
                  class="text--sub-title-left text--secondary-rd mt-1"
                  :class="{ darkFont: this.$vuetify.theme.dark }"
                >
                  {{ $t('workCardCurrentBid') }}
                </div>
                <div class="d-flex flex-row">
                  <div>
                    <v-img
                      :src="getChainByName(value.tokenType).img"
                      alt="logo"
                      width="22px"
                      height="22px"
                    />
                  </div>
                  <div
                    class="ml-2 text--number text--primary-rd"
                    style="line-height: 22px;font-weight:bold;!important"
                    :class="{ darkFont: this.$vuetify.theme.dark }"
                  >
                    {{ value.auction.lastBidPrice }}
                  </div>
                </div>
              </div>
              <div>
                <div
                  class="text--sub-title text--secondary-rd mt-1"
                  :class="{ darkFont: this.$vuetify.theme.dark }"
                >
                  {{ $t('workCardEndingin') }}
                </div>
                <div>
                  <div
                    class="text--number text--primary-rd font-weight-medium mt-1"
                    :class="{ darkFont: this.$vuetify.theme.dark }"
                  >
                    {{ hour }}h {{ minutes }}m {{ second }}s
                  </div>
                </div>
              </div>
            </div>
            <div class="btnbox d-flex flex-row" v-else>
              <button
                class="rd"
                @click.stop="AbanBtn"
                v-if="
                  value.auction.lastBidPrice != null && value.auction.isNotBid
                "
                :class="{ darkFont: $vuetify.theme.dark }"
              >
                {{ $t('Withdraw') }}
              </button>
              <button
                class="more"
                v-if="
                  value.auction.lastBidPrice != null && value.auction.isNotBid
                "
                @click="openMore"
              >
                ...
              </button>
              <button class="Allmore" v-else @click="openMore">...</button>
            </div>
          </div>
          <div class="moreList" v-if="showMore">
            <div class="title-15" @click.stop="saleTotalBtn">
              {{ $t('popupSaletotalSalesStatistics') }}
            </div>
            <div
              class="title-15"
              v-if="
                value.assetStatus == 2 && value.creator == this.$store.state.did
              "
              @click.stop="AppealBtn"
            >
              {{ $t('headerUserCenterNavAppeal') }}
            </div>
            <div
              class="title-15"
              v-if="value.isBurn == true"
              @click.stop="burnBtn"
            >
              {{ $t('popupAuctionDefectPriceBurnNFT') }}
            </div>
          </div>
        </template>
      </template>
      <template v-if="value.marketType == '6'">
        <template
          v-if="
            value.saleStatus == '6' ||
            value.saleStatus == '7' ||
            value.saleStatus == '8' ||
            value.saleStatus == '9'
          "
        >
          <div
            class="d-flex justify-center align-center pt-1 text--info text--secondary-rd"
            :class="{ darkFont: this.$vuetify.theme.dark }"
          >
            {{ $t('workCardInprogress') }}
          </div>
        </template>

        <template v-else>
          <div class="notOnsale mt-lg-n3">
            <div class="nftInfo" v-if="!isShowCard">
              <div class="title-13">{{ $t('Best_offer') }}</div>
              <div
                class="title-13"
                v-if="value.offerDto && value.offerDto.orderPrice"
              >
                {{ value.offerDto.orderPrice }}
                {{ value.offerDto.payTokenType }}
              </div>
              <div class="title-13" v-else>-</div>
            </div>
            <div class="btnbox d-flex flex-row" v-else>
              <button
                class="rd"
                @click.stop="WithdrawBtn"
                :class="{ darkFont: $vuetify.theme.dark }"
              >
                {{ $t('Withdraw') }}
              </button>

              <button class="more" @click="openMore">...</button>
            </div>
          </div>

          <div class="moreList" v-if="showMore">
            <div class="title-15" @click.stop="showQRcode">
              {{ $t('ReceivingSale') }}
            </div>

            <div class="title-15" @click.stop="saleTotalBtn">
              {{ $t('popupSaletotalSalesStatistics') }}
            </div>
            <div
              class="title-15"
              v-if="
                value.assetStatus == 2 && value.creator == this.$store.state.did
              "
              @click.stop="AppealBtn"
            >
              {{ $t('headerUserCenterNavAppeal') }}
            </div>
            <div
              class="title-15"
              v-if="value.isBurn == true"
              @click.stop="burnBtn"
            >
              {{ $t('popupAuctionDefectPriceBurnNFT') }}
            </div>
          </div>

          <!-- <v-btn
            class="sale"
            dark
            rounded
            depressed
            width="178"
            height="28"
            @click="WithdrawBtn"
            >{{ $t("Withdraw") }}
          </v-btn> -->
        </template>
      </template>

      <!-- 被禁止/被投诉 -->
    </v-card-text>

    <!-- 弹层部分 -->
    <!-- 申诉 -->
    <v-dialog v-model="Appeal">
      <nftAppeal
        :parentValue="value"
        appealType="commonAppeal"
        @getOpen="Open"
      ></nftAppeal>
    </v-dialog>
    <!-- 转送/新建转送 -->
    <v-dialog v-model="Transfer">
      <new-transfer
        :imgUrl="imgUrl"
        :address="address"
        :level="1"
        :name="name"
        :contracId="value.id"
        :offSaleTokenIds="offSaleTokenIds"
        :parentValue="value"
        @getOpen="opentransfer"
        @promptBoxCommit="promptBoxCommit"
      ></new-transfer>
    </v-dialog>
    <!-- 新增 上架选择 -->
    <v-dialog v-model="openputonsale">
      <put-on-sale
        @getOpen="openPutOnSale"
        :address="address"
        :onSaleTokenIds="onSaleTokenIds"
        :offSaleTokenIds="offSaleTokenIds"
        :supplyLimit="supplyLimit"
        :name="name"
        :parentValue="value"
        v-bind="$attrs"
        v-on="$listeners"
      ></put-on-sale>
    </v-dialog>
    <!-- 销毁 -->
    <v-dialog v-model="Burning">
      <burn v-if="Burning" :pageValue="value" @getOpen="openBurn"></burn>
    </v-dialog>
    <!-- 结束售卖 -->
    <v-dialog v-model="Sold">
      <soldout
        :address="address"
        :onSaleTokenIds="onSaleTokenIds"
        :offSaleTokenIds="offSaleTokenIds"
        :name="value.name"
        :parentValue="value"
        @getOpen="openSold"
      ></soldout>
    </v-dialog>
    <!-- 销售统计 -->
    <v-dialog v-model="Total">
      <saletotal
        v-if="Total"
        :pageData="value"
        :address="address"
        :id="value.id"
        :name="value.name"
        :minPrice="value.minPrice"
        @getOpen="openTotal"
      ></saletotal>
    </v-dialog>
    <!-- 卖完了 -->
    <v-dialog v-model="Saleout">
      <soldout @getOpen="openSaleout"></soldout>
    </v-dialog>
    <!-- 未达到目的  放弃   / 及 撤销 使用  -->
    <v-dialog v-model="openaban">
      <auction-aban :parentValue="value" @getOpen="openAban"></auction-aban>
    </v-dialog>

    <!-- 达到目的待确认 -->
    <v-dialog v-model="opendeal">
      <auction-in-sure
        :auctionNo="auctionNo"
        @getOpen="openDeal"
      ></auction-in-sure>
    </v-dialog>
    <!-- 跨链 crossChainTransfer-->
    <v-dialog v-model="crossChain" v-if="crossChain">
      <CrossChainTransfer
        :parentValue="value"
        @getOpen="OpenCrossChain"
      ></CrossChainTransfer>
    </v-dialog>
    <!-- 被投诉提醒框 -->
    <v-dialog v-model="Banprompt">
      <banprompt v-if="Banprompt" @getOpenBan="openBanprompt"></banprompt>
    </v-dialog>
    <v-dialog v-model="OpenQrCode">
      <receiveQrCode
        v-if="OpenQrCode"
        @getOpenQRcode="openQrCode"
        :Nft_sku="value"
      ></receiveQrCode>
    </v-dialog>
    <v-dialog v-model="OpenWithDraw">
      <withDraw
        v-if="OpenWithDraw"
        @getOpenDraw="OpenWithDraws"
        :parentValue="value"
        :name="value.name"
        :address="address"
      ></withDraw>
    </v-dialog>

    <!-- pickUp  from   -->
    <v-dialog v-model="openPickupDetail" v-if="openPickupDetail">
      <RwaPickupDetail
        @getOpen="OpenPickUpDetailDialog"
        :productDetail="value"
      ></RwaPickupDetail>
    </v-dialog>
    <!-- pickUp  from   -->
    <v-dialog v-model="OpenPickUpFrom" v-if="OpenPickUpFrom">
      <RwaPickupFrom
        @getOpen="OpenPickUpFromDialog"
        :productDetail="value"
        :editDelivery="editDelivery"
      ></RwaPickupFrom>
    </v-dialog>
    <uComponents ref="ucom"></uComponents>
  </div>
</template>

<script lang="js">
import RwaPickupDetail from "@/components/popup/rwaPickupDetail.vue";
import RwaPickupFrom from "@/components/popup/rwaPickupFrom.vue";
import NftAppeal from "@/components/popup/nftAppeal.vue";
// import Transfer from "@/components/popup/transfer.vue";
import newTransfer from "@/components/popup/newTransfer.vue";
import Burn from "@/components/popup/burn.vue";
import Soldout from "@/components/popup/soldout.vue";
import withDraw from "@/components/popup/withDraw.vue";
import Saletotal from "@/components/popup/saletotal.vue";
import PutOnSale from "@/components/popup/putOnSale.vue";
// import countLogo from "@/assets/icons/chain_iris_w.png";
import AuctionAban from "@/components/popup/auctionAban.vue";
import Market from "@/components/popup/market.vue";
import Banprompt from "@/components/popup/banprompt.vue";
import receiveQrCode from "@/components/popup/receiveQrCode.vue";
  import CrossChainTransfer from "@/components/popup/crossChainTransfer.vue";
  import api from "@/api";


export default {
  components: {
    NftAppeal,
    Burn,
    Soldout,
    Saletotal,
    newTransfer,
    PutOnSale,
    AuctionAban,
    Market,
    Banprompt,
    receiveQrCode,
    withDraw,
    CrossChainTransfer,
    RwaPickupDetail,
    RwaPickupFrom
  },
  name: "MyCardContent",
  props: {
    value: {
      type: Object
    },
    isShowCard:{
      type:Boolean
    }
  },
  data: function() {
    return {
      editDelivery:null,
      OpenPickUpFrom:false,
      openPickupDetail:false,
      crossChain:false,
      showMore:false,
      Banprompt:false,
      OpenQrCode:false,
      OpenWithDraw:false,
      show:false,
      Appeal: false,
      Transfer: false,
      openputonsale: false,
      Burning: false,
      Sold: false,
      Total: false,
      Saleout: false,
      openaban: false,
      openisdeal: false,
      opendeal: "",
       countLogo:'',
      now: "",
      start: "",
      end: "",
      insure: "",
      day: 0,
      hour: 0,
      minutes: 0,
      second: 0,
      seconds: "",
      hh: 0,
      mm: 0,
      ss: 0,
      moreseconds: "",
      address: "",
      auctionNo: "",
      imgUrl: "",
      name: "",
      level: 1,
      balance: "",
      onSaleTokenIds: "",
      offSaleTokenIds: "",
      supplyLimit: "",
      avliableLenth: 0,
      minPrice: "",
      nftId: "",
      auctionStatus:'',
      nftType: ""
    };
  },
  mounted() {
    this.init();
  },
   computed: {
  isMobile: function () {
    return this.$vuetify.breakpoint.mobile;
  },
},
  methods: {
    OpenPickUpFromDialog(e,target){
      console.log('wxl --- 8888 getOpen',e);
      this.editDelivery=null;
      this.OpenPickUpFrom =e
      if(!e && target){
        this.toOpenPickUpDetail();
      }
  },
    toOpenConfirmPickUpFrom(){
    this.OpenPickUpFrom = true
  },
    OpenPickUpDetailDialog(e,target){
      this.editDelivery=null;
      console.log('wxl --- 9999 getOpen',e,target);
    this.openPickupDetail =e
    if(target){
      this.editDelivery=target;
      this.OpenPickUpFrom = true


    }

  },
    toOpenPickUpDetail(){
    this.openPickupDetail = true
  },

    crossChainBrn(){
       this.$gtag.event('跨链转移', { 'event_category': 'Click', 'event_label': '跨链转移' })
        this.crossChain = true
    },
    OpenCrossChain(e){
      this.crossChain = e
    },
    leaveMore(){
      this.showMore = false
    },
     init() {
          this.Time(); //调用定时器
          this.address = this.value.contractAddress;
          this.auctionNo = this.value.auctionNo;
          this.imgUrl = this.value.imgUrl;
          this.name = this.value.name;
          this.nftId = this.value.nftId;
          this.onSaleTokenIds = this.value.onSaleTokenIds;
          this.offSaleTokenIds = this.value.offSaleTokenIds;
          this.supplyLimit = this.value.amount;
          this.auctionStatus = this.value.auctionStatus;
          this.nftType = this.value.nftType;
          if (this.value.offSaleTokenIds != null ) {
             this.avliableLenth = this.value.offSaleTokenIds.length;
          }
          if (this.value.onSaleTokenIds != null) {
             this.avliableLenth += this.value.onSaleTokenIds.length;
          }
     },
    getTime() {
      if(this.value.auction){
         this.now = Date.parse(new Date());
      this.start = this.value.auction.startTime;
      this.end = this.value.auction.endTime;
      // this.start = Date.parse(new Date("2022-01-10 14:18:00"));
      // this.end = Date.parse(new Date("2022-01-10 14:19:00"));
      this.insure = 1000 * 60 * 60 * 48 + this.end;//48小时 暂时1小时

      if (this.value.auction.auctionStatus == 4 || this.value.auction.auctionStatus == 0 || this.value.auction.auctionStatus == 1) {
        if(this.value.auction.auctionNoBid&&this.value.auction.auctionNoBid==true){
	   this.insure = 1000 * 60 * 60 * 1 + this.end;//1小时 流拍1小时
  }


  if (this.now < this.start) {
          this.seconds = (this.start - this.now) / 1000;
        } else if (this.now < this.end) {
          this.seconds = (this.end - this.now) / 1000;
        } else if (this.now < this.insure) {
          this.moreseconds = (this.insure - this.now) / 1000;
        }
      }
      if (this.value.auctionStatus == 5) {
        if (this.insure > this.now) {
          this.moreseconds = (this.insure - this.now) / 1000;
        } else {
          this.moreseconds = 0;
        }

      }
      }
       if(this.value.couponTime){
      this.now = Date.parse(new Date());
      this.start = this.value.couponTime.couponStartTime;
      this.end = this.value.couponTime.couponEndTime;
      // this.start = Date.parse(new Date("2022-01-10 14:18:00"));
      // this.end = Date.parse(new Date("2022-01-10 14:19:00"));





    if (this.now < this.start) {
          this.seconds = (this.start - this.now) / 1000;
        } else if (this.now < this.end) {
          this.seconds = (this.end - this.now) / 1000;
        }
      }

    },
    // 天 时 分 秒 格式化函数
    countDown() {
      if (this.seconds > 0) {
        let d = parseInt(this.seconds / (24 * 60 * 60));
        let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
        this.hour = h < 10 ? "0" + h : h;
        let m = parseInt((this.seconds / 60) % 60);
        this.minutes = m < 10 ? "0" + m : m;
        let s = parseInt(this.seconds % 60);
        this.second = s < 10 ? "0" + s : s;
      }
    },
    moreCountDown() {
      if (this.moreseconds > 0) {
        let d = parseInt(this.moreseconds / (24 * 60 * 60));
        let h = parseInt(((this.moreseconds / (60 * 60)) % 24) + d * 24);
        this.hh = h < 10 ? "0" + h : h;
        let m = parseInt((this.moreseconds / 60) % 60);
        this.mm = m < 10 ? "0" + m : m;
        let s = parseInt(this.moreseconds % 60);
        this.ss = s < 10 ? "0" + s : s;
      }
    },
    //定时器没过1秒参数减1
    Time() {
      setInterval(() => {
        if (this.seconds > 0) {
          this.seconds -= 1;
          this.countDown();
        } else if (this.moreseconds > 0) {
          this.moreseconds -= 1;
          this.moreCountDown();
        } else {
          this.getTime();
        }

      }, 1000);
    },
    moused() {
		this.show = true
	},
	leave() {
		this.show = false
	},
    AbanBtn() {
      this.openaban = true;
    },
    openAban(e) {
      this.openaban = e;
    },
    iSdealBtn() {
      this.openisdeal = true;
    },
    openisDeal(e) {
      this.openisdeal = e;
    },
    dealBtn() {
      this.opendeal = true;
    },
    openDeal(e) {
      this.opendeal = e;
    },
    //申诉弹层
    AppealBtn() {
      this.Appeal = true;
    },
    Open(e) {
      this.Appeal = e;
    },
    //转送弹层
    TransferBtn() {
      this.$gtag.event('转送', { 'event_category': 'Click', 'event_label': '转送' })
      this.Transfer = true;
    },
     			 openBanprompt(e){
       this.Banprompt = e
    },
    openQrCode(e){
       this.OpenQrCode = e
    },
    OpenWithDraws(e){
      this.OpenWithDraw = e
    },
    opentransfer(e) {
      if(e == 1) {
         this.Transfer = false;
         location.reload();
      } else {
         this.Transfer = e;
      }
    },
    //开始售卖/拍卖弹层
    putOnSaleBtn() {
       let json = localStorage.getItem("key_user_grade");
    		let jsonObj = JSON.parse(json)

       if(jsonObj.grade == 2){
             this.Banprompt = true
             console.log("wxl ---- 2222")
          }else{
             this.openputonsale = true;
          }
    },
    openPutOnSale(e) {
       if(e == 1) {
          this.openputonsale = false;
          location.reload();
       } else {
          this.openputonsale = e;
       }
    },
    // 打开更多
    openMore(){
      this.showMore = true
    },
    //销毁弹层
    burnBtn() {
       this.$gtag.event('销毁', { 'event_category': 'Click', 'event_label': '销毁' })
      this.Burning = true;
    },
    showQRcode(){
       this.OpenQrCode =true
    },
    WithdrawBtn(){
     this.OpenWithDraw = true
    },
    openBurn(e) {
       if(e == 1) {
          this.Burning = false;
          location.reload();
       }
       else {
          this.Burning = e;
       }
    },
    // 结束售卖弹层
    EndSaleBtn() {
      this.Sold = true;
    },
    openSold(e) {
       if(e == 1) {
          this.Sold = false;
          location.reload();
       } else {
          this.Sold = e;
       }
    },
    //销售统计弹层
    saleTotalBtn() {
        this.$gtag.event('销售统计', { 'event_category': 'Click', 'event_label': '销售统计' })

      this.Total = true;
    },
   async  setAvatar(value){
      let params = {
        nftAddress:value.nftAddress,
        nftId:value.nftId
    };
  let  res = await api.home.setNftAvatar(params);
  if (res.success == true) {
      this.$toast("success", this.$t("success")).then(() => {
        window.eventBus.$emit("backgroundUpdate");
      });
    } else {
      this.$toast("error", this.$t(res.msg));
    }

    },

    openTotal(e) {
      this.Total = e;
    },
    // 卖完弹层
    saleoutBtn() {
      this.Saleout = true;
    },
    openSaleout(e) {
      this.Saleout = e;
      window.location.reload();
    },
    promptBoxCommit(v) {
      if (v != 3) {
        this.Transfer = false;
      }
    },

  }
};
</script>

<style lang="scss" scoped>
.v-card__text {
  padding: 0px !important;
}

.marketPrice {
  text-decoration: line-through;
  font-family: Helvetica;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 15px;
  letter-spacing: 1px;
  color: #766983;
}
.coupenTime {
  font-family: Helvetica;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 15px;
  letter-spacing: 0px;
  color: #270645;
}
.cards {
  //  &:hover{
  //    .notOnsale{
  //       .nftInfo{
  //           display: none !important;
  //     }
  //     .btnbox{
  //       display: block !important;
  //     }

  //    }

  //   }
  .moreList {
    padding: 20px;
    width: 200px;
    min-height: 50px;
    position: absolute;
    bottom: 50px;
    right: 0;
    background-color: #ffffff;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
    .title-15 {
      cursor: pointer;
      font-family: Helvetica;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 25px;
      letter-spacing: 0px;
      color: #270645;
      padding: 5px 0px;
    }
  }
  .notOnsale {
    position: relative;
    .nftInfo {
      text-align: right;
      padding-right: 20px;
    }
    .btnbox {
      // display: none !important;
      .rd {
        text-align: center;
        padding: 2px;
        border-radius: 17px;
        // margin-left: -5px;

        width: 70%;
        height: 48px;
        background-color: #7800f4 !important;
        border-radius: 0px 0px 0px 5px;
        font-family: Helvetica;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #ffffff;
      }
      .Allmore {
        text-align: center;
        padding: 2px;
        border-radius: 17px;

        width: 100%;
        height: 48px;
        background-color: #7800f4;
        border-radius: 0px 0px 5px 5px;
        font-family: Helvetica;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #ffffff;
      }
      .more {
        text-align: center;
        padding: 2px;
        border-radius: 17px;
        margin-left: 0.5%;
        width: 29.5%;
        height: 48px;
        background-color: #7800f4;
        border-radius: 0px 0px 5px 0px;
        font-family: Helvetica;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #ffffff;
      }
    }
  }

  .title-13 {
    font-family: Helvetica;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #766983;
  }

  .nobid {
    height: 13px;
    font-family: Helvetica;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #270645;
  }
  .text--primary-rd {
    color: #270645;
  }

  .text--secondary-rd {
    color: #766983;
  }

  .text--info {
    font-size: 10px !important;
    line-height: 1;
    letter-spacing: 0px;
  }

  .text--title {
    display: inline-block;
    font-size: 13px;
    line-height: 24px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 230px;
  }

  .text--sub-title {
    zoom: 0.83;
    font-family: Helvetica;
    font-size: 13px;
    line-height: 1;
  }
  .text--sub-title-left {
    zoom: 0.83;
    font-family: Helvetica;
    font-size: 13px;
    line-height: 1;
    text-align: left;
  }

  .text--number {
    font-size: 11px;
    line-height: 1;
    font-weight: bold !important;
  }

  .v-divider {
    border-color: #e3e3e3;
  }

  .satrtsale {
    width: 50%;
    height: 28px;
    background-color: #7800f4 !important;
    border-radius: 18px;
    font-family: Helvetica;
    font-weight: bold;
    font-size: 12px !important;
    font-weight: bold;
    font-stretch: normal;
    line-height: 100px;
    letter-spacing: 0px;
    color: #ffffff;
    text-align: center;
  }

  .withdraw {
    width: 80px;
    height: 28px;
    background-color: #7800f4 !important;
    border-radius: 18px;
    font-family: Helvetica;
    font-weight: bold;
    font-size: 12px !important;
    font-weight: bold;
    font-stretch: normal;
    line-height: 100px;
    letter-spacing: 0px;
    color: #ffffff;
    text-align: center;
    border-radius: 17px;
    margin-left: 90px;
    margin-top: -50px;
  }
  .banDescription {
    width: 160px;
    border-radius: 18px;
    font-family: Helvetica;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #766983;
  }

  .sold_out {
    background-blend-mode: normal, normal;
    border-radius: 18px;
    font-family: Helvetica;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #766983;
  }

  .auction_out {
    height: 36px;
    text-align: center;
  }

  .dialodup5 {
    width: 281px;
    height: 281px;
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;

    .tip {
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      margin-top: 121px;
      margin-bottom: 9px;
      text-align: center;
    }

    .timer {
      font-family: Helvetica;
      font-weight: bold;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      margin-bottom: 32px;
      text-align: center;
    }
    .drawbtn {
      width: 244px;
      height: 36px;
      background-color: #270645;
      border-radius: 17px;
      margin-left: 18px;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 36px;
      text-align: center;
      letter-spacing: 0px;
      color: #ffffff;
      margin-top: 210px;
    }

    .dealbtn {
      width: 244px;
      height: 36px;
      background-color: #270645;
      border-radius: 17px;
      margin-left: 18px;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 36px;
      text-align: center;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }

  .dialodup4 {
    width: 281px;
    height: 281px;
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;

    .tip {
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      margin-top: 105px;
      margin-left: 28px;
      margin-bottom: 9px;
    }

    .timer {
      font-family: Helvetica;
      font-weight: bold;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      margin-bottom: 32px;
      text-align: center;
    }

    .btn {
      .box {
        width: 115px;
        height: 36px;
        box-sizing: border-box;
        padding: 2px;
        background-image: linear-gradient(
          0deg,
          #d202fd 0%,
          #a538fe 26%,
          #776eff 51%,
          #01facd 100%
        );
        border-radius: 18px;

        .abanbtn {
          width: 100%;
          height: 100%;
          border: none;
          border-radius: 20px;
          background: #fff;
          font-family: Helvetica;
          font-weight: bold;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0;
          color: #270645;
        }
      }

      .dealbtn {
        width: 116px;
        height: 36px;
        background-color: #270645;
        border-radius: 17px;
        margin-left: 18px;
        font-family: Helvetica;
        font-weight: bold;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0px;
        color: #ffffff;
      }
    }
  }

  .sale {
    background-color: #7800f4 !important;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 17px;
    background: #fff;
    font-family: Helvetica;
    font-weight: bold;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0;
    margin-top: -10px;
  }
  .explain {
    width: 185px;
    height: 56px;
    background-color: #270645;
    border-radius: 3px;
    position: absolute;
    top: 327px;
    right: 30px;
    z-index: 2;

    span {
      font-family: Helvetica;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 15px;
      letter-spacing: 0px;
      color: #ffffff;
      margin-left: 13px;
      margin-right: 13px;
    }
  }

  &.mobile {
    .banDescription {
      font-size: 12px;
      line-height: 15px;
    }
    .sale {
      width: 100% !important;
      height: 70% !important;
    }
    .satrtsale {
      width: 100%;
    }
    .btnbox {
      width: 100%;
    }
    .sold_out {
      width: 100%;
    }
  }
}
</style>
